<template>
  <div>
    <Header />

    <section class="container-fluid">
        <div class="page-header">
            <h2 class="header-text">SWEEPSTAKES {{ $route.params.id.toUpperCase() }} ENTRY</h2>
            <p class="header-content">THANK YOU FOR LOGGING IN TODAY, YOU HAVE 1 FREE ENTRY TO A DRAW OF YOUR CHOICE</p>
        </div>
        <div class="cta-mobile">
            <p>
                View my sweepstakes entries in <a href="/my-account">My account</a>.
            </p>
        </div>
        <section class="ticket">
            <div class="ticket-header">
                <h2>{{  $route.params.id.toUpperCase() }} DRAW</h2>
                <div class="reward">
                    <span>REWARD<span v-if="$route.params.id !== 'daily'">(USD)</span>:</span>
                    <span>{{ displayReward }}</span>
                </div>
            </div>
            <div class="ticket-content">
                <div class="mobile-only">
                    <span>REWARD<span v-if="$route.params.id !== 'daily'">(USD)</span>:</span>
                    <span>{{ displayReward }}</span>
                </div>
                <div class="left-content">
                    <img :src="drawImg" />
                </div>
                <div class="right-content">
                    <div class="details">
                        <label>QTY</label>
                        <input
                            type="text"
                            id="qty"
                            name="qty"
                            readonly
                            value="1"
                        />
                    </div>
                    <div class="details">
                        <label>TICKET COST</label>
                        <input
                            type="text"
                            id="tickets"
                            name="tickets"
                            readonly
                            :value="lottery.requiredtickets"
                        />
                    </div>
                    <div class="details">
                        <button class="btn" @click.prevent="joinLottery('')">
                            EXCHANGE
                        </button>
                        <button class ="btn-free" @click.prevent="joinLottery('free')">
                            USE MY FREE ENTRY
                        </button>
                    </div>
                    <div class="cta-desktop details">
                        <p>
                            View my sweepstakes entries in <a href="/my-account">My account</a>.
                        </p>
                    </div>
                
                </div>
            </div>
       </section>

        <b-modal v-model="showModal" centered hide-footer>
            <template #modal-title>
                <img src="@/assets/logo-white.png" />
            </template>
            <div class="d-block text-center" v-if="isLotterySuccess">
                <div class="title">
                    <h3>YOU ARE IN THE DRAW TO WIN THE {{$route.params.id.toUpperCase()}} PRIZE</h3>
                </div>
                <div class="content">
                    <div class="img-wrapper">
                        <img class="img-fluid img-thumbnail rounded-cirle" :src="drawImg" />
                    </div>
                    <div class="text-wrapper">
                        <p>
                            The {{$route.params.id}} draw is drawn at 12mn (NY EST. TIME) {{ drawMessage[$route.params.id] }}.
                        </p>
                        <p>
                            Your tickets are saved into <a href="/my-account">My Account</a>, log back in later to see if you've won!
                        </p>
                    </div>
                </div>
            </div>
            <div class="d-block text-center" v-else>
                <div class="error">
                    <h3>{{ errorMessage }}</h3>
                </div>
            </div>
        </b-modal>
    </section>
    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
const token = localStorage.getItem("token");
import { mapState } from "vuex";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
export default {
  name: "SweepstakesBuy",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      lottery: {
        drawdate: "",
        drawdatetime: "",
        requiredtickets: 0,
        prizeid: 0,
        prize: "",
        lottertype: 0,
        id: 0,
        code: "",
      },
      drawImg: '',
      showModal: false,
      isLotterySuccess: false,
      errorMessage: '',
      drawMessage: {
          daily: 'everyday',
          weekly: 'every Sunday of the week',
          monthly: 'every last Sunday of the month'
      }
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
    }),
    displayReward() {
        switch(this.$route.params.id) {
            case 'daily':
                return '250 TICKETS'
            case 'weekly':
                return '20.00 USD'
            case 'monthly':
                return '50.00 USD'
            default:
                return ''
        }
    }
  },
  mounted() {
      const { id } = this.$route.params
      switch (id) {
            case 'daily':
                this.drawImg = require('../assets/Component 4.png')
            break;
            case 'weekly':
                this.drawImg = require('../assets/weekly.png')
            break;
            case 'monthly':
                this.drawImg = require('../assets/monthly.png')
            break;

      }
    this.getLotterytype();
  },
  methods: {
    gotoAccount() {
      this.$router.push("/my-account");
    },
    getLotterytype() {
        const arr = ['daily', 'weekly', 'monthly']
      let id = arr.findIndex(e => e === this.$route.params.id) + 1;
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      axios
        .get(apiurl + "lottery/lotteries/" + id, { headers: headers })
        .then((response) => {
          this.lottery = response.data[0];
        });
    },
    joinLottery(type) {
      const { id: lotteryType, code } = this.lottery

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const param = {
        lotteryType,
        userID: this.account.user.data.id,
        code,
      };
      axios
        .post(`${apiurl}lottery/join${type}lottery`, param, { headers })
        .then((response) => {
            this.isLotterySuccess = response.data.result.includes("Ticket sent")
            this.errorMessage = type === 'free' ? 'You have already used your free entry today. Come back tomorrow to place another entry.' : 'You have reached the maximum amount of entries permitted for this draw.'
            this.showModal = true;
        })
    },
    
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/sweepstakebuy.scss";
.page-header { 
    margin: auto;
    text-align: center;
    padding-bottom: 0;
    margin-top: 64px;

    @media (min-width: 576px) {
        margin-bottom: 48px;
    }

    .header-text {
        font-weight: 700;
        font-size: 2rem;

        @media(min-width: 576px) {
            font-size: 3rem;
        }
    }

    .header-content {
        font-size: 1rem;

        @media (min-width: 576px) {
            font-size: 1.5rem;
        }
    }

}

.cta-mobile {
    margin-bottom: 32px;

    a {
        text-decoration: underline;
    }

    @media (min-width: 576px) {
        display: none;
    }
}

.cta-desktop {
    display: none;

    @media (min-width: 576px) {
        display: flex;
        margin-top: 32px !important;

        a {
            color: white;
            text-decoration: underline;
        }
    }
}

.ticket {
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    border: 0;
    overflow: hidden;
    width: 100%;
    max-width: 1150px;
    margin: auto;

    .ticket-header {
        padding: 18px 36px;
        text-align: left;
        width: 100%;
        background-color: #6b6b6b;
        color: white;

        .reward {
            display: none;
        }

        @media (min-width: 576px) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                margin-bottom: 0;
            }

            .reward {
                padding: 12px;
                background-color: #434343;
                border-radius: 24px;
                min-width: 40%;
                display: flex;
                justify-content: space-between;
                font-weight: bold;
            }
        }
    }

    .ticket-content {
        background-color: #063B58;
        display: flex;
        flex-direction: column;

        

        .mobile-only {
            margin: 24px auto;
            color: white;
            padding: 12px;
            background-color: #434343;
            border-radius: 24px;
            width: calc(100% - 48px);
            display: flex;
            justify-content: space-between;
            font-weight: bold;
 
        }

        @media (min-width: 576px) {
            flex-direction: row;
            align-items: stretch;
            .mobile-only {
                display: none;
            }
        }

        .left-content {
            width: 100%;
            padding: 12px;
            img {
                width: 100%;
                max-width: 376px;
                object-fit: contain;
            }

            @media (min-width: 576px) {
                width: auto;
                padding: 18px 36px;
                margin-right: 32px;
            }
        }

        .right-content {
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &> * {
                margin-top: 18px;
            }

            .details {
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: flex-end;
                color: white;
                font-weight: 400;

                &> * {
                    margin-left: 12px;
                    margin-right: 12px;
                }

                input {
                    width: 148px;
                    height: 48px;
                    color: #063B58;
                    border-color: #063B58;
                    border-radius: 8px;
                    font-weight: 400;
                }

                &:first-child {
                    @media (min-width: 576px) {
                        margin-top: 48px;
                    }
                }
            }

            @media (min-width: 769px) {
                padding: 18px 36px;
                flex-basis: 50%;
                width: 50%;
            }
        }
    }
}

.btn {
    font-weight: 400;
    font-size: 16px;
    color: #063B58;
    background-color: #FFFFFF;
    border-radius: 11px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 24px;
}

.btn-free {
    font-weight: 400;
    font-size: 16px;
    color: #063B58;
    background-color: #06DFD3;
    border-radius: 11px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 24px;
}
::v-deep .modal-dialog-centered {
    @media (min-width: 769px) {
        min-width: 65%;
    }
}

::v-deep .modal-header {
    border: none;
    background-color: #063B58;
}

::v-deep .modal-body {
    background: url("../assets/sweepstakes-modal-background.png");
    background-position: center;
    background-size: cover;
    background-repeat:  no-repeat;
    .title {
        color: #063B58;
        padding: 18px;
        h3 {
            font-size: 2rem;
        }
    }

    h3 {
        margin-bottom: 24px;
        font-weight: 700;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;

        @media (min-width: 980px) {
            flex-direction: row;
        }
    }

    .img-wrapper {
        max-width: 252px;
        max-height: 252px;
        border: 10px solid #145484;
        border-radius: 50%;
        z-index: 1;

        img {
            background: transparent;
            border: 0;
            padding: 0;
        }
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #063B58;
        color: white;
        padding: 32px;
        z-index: 0;
        min-height: 180px;
        border-radius: 40px;
        margin-top: -125px;

        p {
            font-weight: 700;
            font-size: 1.2rem;
            &:first-child {
                margin-top: 125px;
                @media (min-width: 980px) {
                    margin-top: auto;
                }
            }
            &:last-child {
                margin-bottom: 72px;
                @media (min-width: 980px) {
                    margin-bottom: auto;
                }
            }
 
        }

        @media (min-width: 980px) {
            width: 75%;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: -72px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            p {
                margin-left: 42px; 
                font-weight: 700;
                font-size: 1.2rem;
            }
        }

        a {
            color: white;
            text-decoration: underline;
        }
    }

    .error {
        color: #063B58;
        max-width: 55%;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 72px;

        h3 {
            text-transform: uppercase;
        }
    }
}

::v-deep .close {
    opacity: 1;
    background-color: #063B58;
    border-radius: 50%;
    color: white;
    margin: 0 !important;
    margin-top: -10px !important;
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
    padding-top: 0.8rem !important;

    &:hover {
        opacity: 1;
        background-color: #063B58;
        color: white;
    }
    
}

</style>
