import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import store from './store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import titleMixin from './titleMixin';
import Embed from 'v-video-embed';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
    
const firebaseConfig = {
  apiKey: 'AIzaSyDp-CQIdvnrRNYKa879RdtekpawBvtZSLU',
  authDomain: 'pachiplus-43534.firebaseapp.com',
  projectId: 'pachiplus-43534',
  storageBucket: 'pachiplus-43534.appspot.com',
  messagingSenderId: '254571701940',
  appId: '1:254571701940:web:9d02ff75caaef5780819e1',
  measurementId: 'G-GRXSGRD53S'
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
// global register
Vue.use(Embed);

// add to library
library.add(fas,
  far,
  )

// alias
Vue.prototype.$analytics = getAnalytics(app);
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Vue-router
Vue.use(VueRouter)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.mixin(titleMixin)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
