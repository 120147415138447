<template>
  <div>
    <Header />

    <section class="container-fluid">
        <section style="position: relative;">
            <div class="header-group">
                <div class="header-container">
                    <h2>Collecting Prizes has never been this fun!</h2>
                    <p>You've played well. Now, reap the rewards!</p>
                </div>
            </div>

            <img
            src="@/assets/bannershop.png"
            alt="Pachi Exchange Banner"
            class="banner desktop-banner"
            />
            <img
            src="@/assets/bannershop-mobile.png"
            alt="Pachi Exchange Banner"
            class="banner mobile-banner"
            />
        </section>

        <section class="pachi-shop">
            <div class="header">
                <h2 class="header-text">Your Pachi Tickets to Prizes</h2>
        </div>
            <div class="shop-container">
                <div class="left-content">
                    <div class="flex-item step-img-container">
                        <img src="@/assets/Group1.png"/>
                    </div>
                    <img class="flex-item" src="@/assets/shop/shopbns.png" />
                    <div class="flex-item">
                        <h4 class="content-header">
                            Browse and Select
                        </h4>
                        <p class="content-text">
                            Browse through our available premium prizes, cash cards and gift vouchers. Select the ones you like and determine the quantity.
                        </p>
                    </div>
            </div>
                <div class="arrow-container">
                    <img class="arrow-desktop" src="@/assets/arrow-white.png" alt="Arrow" />
                    <img class="arrow-mobile" src="@/assets/arrow-down-white.png" alt="Arrow" />
                </div>
                <div class="center-content">
                    <div class="flex-item step-img-container">
                        <img src="@/assets/Group2.png" />
                    </div>
                    <img class="flex-item" src="@/assets/shop/shopacc.png" />
                    <div class="flex-item">
                        <h4 class="content-header">
                            Add To Cart And Confirm

                        </h4>
                        <p class="content-text">
                            Add the items to your cart and follow the prompts to finalize your and exchange and confirm its processing.
                        </p>
                    </div>
                </div>
                <div class="arrow-container">
                    <img class="arrow-desktop" src="@/assets/arrow-white.png" alt="Arrow" />
                    <img class="arrow-mobile" src="@/assets/arrow-down-white.png" alt="Arrow" />
                </div>
    
                <div class="right-content">
                    <div class="flex-item step-img-container">
                        <img src="@/assets/Group3.png" />
                    </div>
                    <img class="flex-item" src="@/assets/shop/shopcc.png" />
                    <div class="flex-item">
                        <h4 class="content-header">
                            Claim And Collect
                        </h4>
                        <p class="content-text">
                            An email notification will be sent to you once you exchange has been successful. The reward items will then be added to your collection.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="rewards">
            <div class="header">
                <h2 class="header-text">
                    Select From The Following Premium Prizes And Rewards
                </h2>
                <div class="cards-container" v-if="exchangeItems.length">
                    <div class="card" v-for="item in exchangeItems" :key="item.id" @click="exchangeItem(item.id)">
                        <img clas="card-img-top" :src="getImgUrl(item.imagebig)" />
                        <button class="btn">Exchange</button>
                    </div>
                </div>
                <div v-else>
                    <h4 class="header-content">
                        No prizes and rewards available
                    </h4>
                </div>
            </div>
        </section>
    </section>

    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
// import { userService } from '../services';
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
export default {
  name: "PachiShop",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      exchangeItems: [], 
      showModal: false,
    };
  },
  created() {
    this.getShopItems();
  },
  methods: {
    getShopItems() {
    const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(apiurl + "items/itemsforchange", { headers: headers })
        .then((response) => {
          this.exchangeItems = [...response.data];
        });
    },
    exchangeItem(item, itemtype) {
      if(itemtype == 2){
        this.$router.push({ path: `shop/exchange/${item}` });
      }else{
        this.$router.push({ path: `shop/exchange/${item}` });
      }
     
    },
    getImgUrl(pic) {
      return require('../assets/shop/'+pic)
    }
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
    padding: 0;

    .banner {
        width: 100%;
    }

    .desktop-banner {
        @media (max-width: 576px) {
            display: none;
        }
    }

    .mobile-banner {
        @media (min-width: 576px) {
            display: none;
        }

    }
}
.header-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 16px;
    color:#063B58;
    @media (min-width: 769px) {
        align-items: flex-end;
    }

    .header-container {
        width: 100%;
    }
    
   p {
        font-size: 24px;
        font-weight: bold;
    }

    @media (min-width: 769px) {
        top: 10px;
        width: 35%;
        left: 16px;
        position: absolute;

        h2 {
            text-align: left;
            font-size: 2.2rem;
            font-weight: 700;
        }
 
        p {
            text-align: end;
            font-size: 1.2rem;
        }
    }


    @media (min-width: 1024px) {
        top: 150px;
        width: 30%;
        text-align: left !important;
        color:#fff;
    }

    @media (max-width: 500px) {
        color:#063B58;
       
        h2 {
            text-align: center;
            font-size: 18;
            font-weight: 700;
        }
 
        p {
            font-size: 14px;
        }
    }

}

.pachi-shop {

    padding: 32px 24px;
    background-color: #063B58; 
    color: white;
    .header {
        line-height: 52px;
        margin: auto;
        font-weight: 700;

        .header-text {
            font-size: 1.5rem;

            @media (min-width: 576px) {
                font-size: 3rem;
            }
        }

        

    }
    .shop-container {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (min-width: 769px) {
            flex-direction: row;
            height: 500px;
        }

        .left-content, .center-content, .right-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .flex-item {
                padding-top: 12px;
                padding-bottom: 12px;

                &:first-child {
                    padding-top: 0;
                }

                .content-header {
                    font-weight: 700;
                }

                .content-text {
                    font-weight: 400;
                    font-size: 18px;
                }
            }

            .step-img-container {
                max-width: 90px;
            }
            
            img {
                max-width: 150px;
                width: 100%;
                object-fit: contain;
            }
            
        }

        .right-content {
            img {
                max-width: 144px;
                width: 100%;
                object-fit: contain;
            }
       }

        .arrow-container {
            .arrow-desktop {
                align-self: center;
                width: 100%;
                object-fit: contain;
                max-width: 220px;
                min-width: 100px;
                display: none;
                @media (min-width: 769px) {
                    display: block;
                    margin: 0 16px;
                }
            }
            .arrow-mobile {
                display: block;
                width: 100%;
                object-fit: contain;
                max-width: 80px;
                margin-bottom: 32px;
                @media (min-width: 769px) {
                    display: none;
                }
            }
    
        }
    }

    .exchange-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 1150px;
        margin: auto;
        margin-top: 32px;
        margin-bottom: 32px;
        @media (min-width: 576px) {
            flex-direction: row;
            .left-content {
                margin-right: 32px;
            }
            .right-content {
                margin-left: 32px;
            }
        }
    }
}

.rewards {
    color: #063B58;
    padding: 32px 24px;
    .header-text {
        font-weight: 700;
        font-size: 1.5rem;

        @media (min-width: 576px) {
            font-size: 3rem;
        }
    }

    .cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        max-width: 1350px;
        margin: auto;

        &::after {
            content: '';
            min-width: 271px; // Same width of card element
            max-width: 271px;
            flex: 0 0 25%;
        }

        .card {
            margin: 12px;
            align-items: center;
            border: 0;
            justify-content: flex-end;

            min-width: 271px;
            min-height: 474px;

            &:hover {
                cursor: pointer;
            }

            img {
                max-width: 271px;
                margin-bottom: 12px;
            }

            .btn {
                margin-top: 12px;
                font-weight: 400;
                font-size: 16px;
                color: #fff;
                background-color: #063B58;
                border-radius: 11px;
                text-align: center;
                text-transform: uppercase;
                padding: 12px 24px;
            }
        }

        &:after {

            @media (min-width: 576px) {
                content: "";
                flex: auto;
            }
       }
    }
}
</style>
