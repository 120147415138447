import { render, staticRenderFns } from "./TrophyDragon.vue?vue&type=template&id=e16f05c2&scoped=true&"
import script from "./TrophyDragon.vue?vue&type=script&lang=js&"
export * from "./TrophyDragon.vue?vue&type=script&lang=js&"
import style0 from "./TrophyDragon.vue?vue&type=style&index=0&id=e16f05c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e16f05c2",
  null
  
)

export default component.exports