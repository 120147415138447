<template>
  <div class="footer">
        <div class="wrapper">
            <img
            id="footerlogo"
            alt="Pachi Exchange logo"
            src="@/assets/footerlogo.png"
            />
        </div>
        <div class="language">
            <div class="language-dropdown">
                <span>Language</span>
                <div class="dropdown">
                    <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >
                    English
                    </button>
                    <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    >
                    <a class="dropdown-item" href="#">English</a>
                    <a class="dropdown-item" href="#">Filipino</a>
                    <a class="dropdown-item" href="#">Chinese</a>
                    </div>
                </div>
            </div>
            <a href="http://knowledgebase.pachiplus.com/" target="_blank"
                ><u>Help Center</u></a
            >
        </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #063B58;
    padding: 10px 20px;

    .language {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        font-weight: bold;

        a {
            color: #FFFFFF;
        }
    }

    .language-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;

        .dropdown-toggle {
            margin-left: 12px;
            color:  #063B58;
            border-radius: 10px;
            border-color: #fff;
            background: #fff;
        }
    }
}
</style>