<template>
  <div>
    <Header />
    <Stepper :stepperItems="['Review Items', 'Confirm Redemption']" :step="2" />
    <section>
        <div class="container shop-exchange">
            <div class="left-content">
                <img src="@/assets/like.png"/>
            </div>
            <div class="right-content">
                <div class="desktop-header">
                    <h3 class="header-text"> Great! You’ve just added cash into your Pachi e-Wallet</h3>
                </div>
                <div class="mobile-header">
                    <h3 class="header-text">Great! You’ve just added cash into your Pachi e-Wallet</h3>
                </div>
                <img class="mobile-img" src="@/assets/like.png"/>
                <div class="content">
                    <p class="content-text">
                        Your Premium Trophy has been successfully converted into cash value. You may check your Pachi e-Wallet in
                        <a style="color:#063B58;text-decoration:underline;" href="/my-account">My Account</a>.
                        Earn more Pachi Tickets to keep your Prize collection growing!
                    </p>
                    <button class="btn" @click.prevent="redirectToMyAccount"> 
                        redeem more
                    </button>
                </div>
            </div>
        </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from '../components/Stepper.vue'
import { router } from "../router";
export default {
  name: "ProductConfirm",
  components: {
    Header,
    Footer,
    Stepper
  }, 
  methods:{
    redirectToMyAccount(){
     router.push("/my-account");
    }
  }
};
</script>

<style lang="scss" scoped>
.shop-exchange {
        padding: 32px 0;
        display: flex;
        flex-direction: row;

        .left-content {
            display: none;

            @media (min-width: 576px) {
                display: block;
                margin-right: 40px;
                padding: 24px;
           }
        }

        .right-content {
            text-align: center;
            padding: 12px;

            &> * {
               margin: 18px 0; 
            }

            p {
                text-align: center;

                @media (min-width: 576px) {
                    text-align: left;
                }
            }

            @media(min-width: 576px) {
                margin-left: 40px;
                padding: 0;
                text-align: left
            }
        }

        .mobile-header {
            @media(min-width: 576px) {
                display: none;
            }
        }

        .mobile-img {
            @media(min-width: 576px) {
                display: none;
            }
        }

        .desktop-header {
            display: none;

            @media(min-width: 576px) {
                display: block;
                text-align: left;
            }
        }

        .header-text,.header-content {
            font-weight: 700;
            font-size: 1.8rem;
            text-transform: capitalize;
            @media(min-width: 576px) {
                font-size: 3rem;
            }
 
        }

        .header-text {
            color: #063B58;
        }

        .header-content {
            color: #94bee3;
        }
    }

    .btn {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        background-color: #063B58;
        border-radius: 11px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 24px;

        &:hover {
            color: #034B73;
            outline: none;
        }
    }
    
</style>
