<template>
  <div>
    <Header />

    <section>
        <div class="container shop-exchange" v-if="hasItem">
            <div class="left-content">
                <div class="img-wrapper">
                    <img :src="getImgUrl(item.imagebig)" />
                </div>
            </div>
            <div class="right-content">
                <div class="header">
                    <h3 class="header-text">{{ dataToDisplay.title }}</h3>
                    <h3 class="header-content"> {{ formatSubtitle }}</h3>
                </div>
               <img class="mobile-img" :src="getImgUrl(item.imagebig)" />
                <div v-for="(content, idx) in dataToDisplay.content" :key="`content-${idx}`">
                    <p class="content">
                        {{ content }}
                    </p>
                </div>
                <div v-for="(disclaimer, idx) in dataToDisplay.disclaimer" :key="`disclaimer-${idx}`">
                    <p class="disclaimer">
                        {{ disclaimer }}
                    </p>
                </div>
                <form class="form-inline">
                    <div class="form-group">
                        <label class="form-label">QTY:</label>
                        <label class="form-label">1</label>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="tickets">Tickets:</label>
                        <label class="tickets" id="tickets" name="tickets">
                            {{ item.tickets }}
                        </label>
                    </div>
                    <input
                        class="btn"
                        @click.prevent="insertExchangeItem()"
                        type="submit"
                        value="CONTINUE"
                    />
              </form>
            </div>
        </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
// import { userService } from '../services';
import { mapState } from "vuex";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { router } from "../router";
import {
    isPremiumItems,
    populateData,
    getMysteryBoxSubtitle
} from '../lib/shop-exchange'

const token = localStorage.getItem("token");
const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
};

export default {
  name: "ShopExchange",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
        hasItem: false,
        item: {
        },
        showModal: false,
        items: {},
        dataToDisplay: {}
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
    }),
    formatSubtitle: function() {
        if (isPremiumItems(this.item.code))
            return `${this.item.code} - $${parseFloat(this.item.amount).toFixed(2)}`
        if (this.item.code.toLowerCase() === 'mystery')
            return `${getMysteryBoxSubtitle(this.item.name)} Size`
        return `$${parseFloat(this.item.amount).toFixed(2)}`
      }
  },
  created() {
    this.getItemforChange();
  },
  methods: {
    getItemforChange() {
     // const id = this.account.user.data.id;
      axios
        .get(apiurl + "items/itemforchangedetails/" + this.$route.params.id, {
          headers,
        })
        
        .then((response) => {

            this.item = {...response.data}
            this.hasItem = true;

            this.dataToDisplay = populateData(this.item);
        })
        .catch(function (error) {
            if(error.response.status==401){
                 router.push("/logout");
            }
        });
    },
    buyOnShop() {},
    insertExchangeItem() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const param = {
        exchangeId: this.item.id,
        userId: this.account.user.data.id,
      };

      const { itemType} = this.item;

      axios
        .post(`${apiurl}items/exchangeitems${itemType === 1 ? '' : 'gc'}`, param, { headers })
        .then((response) => {
          this.showModal = true;
          if (response.data.result == "SUCCESS") {
              let url = ''
              switch (itemType) {
                case 1:
                    url = 'trophy';
                    break;
                case 2:
                    url = 'giftcard';
                    break;
                case 4:
                    url = 'mysterybox';
                    break;
                default:
                    url = 'giftcard'
              }
            router.push(`/product/confirmation${url}`);
          }else{
              alert(response.data.message);
          }
        });
    }, 
    getImgUrl(pic) {
      return require('../assets/shop/'+pic)
    }
  }
 
};
</script>

<style lang="scss" scoped>
    .shop-exchange {
        padding: 32px 0;
        display: flex;
        flex-direction: row;

        .left-content {
            display: none;

            @media (min-width: 576px) {
                display: block;

                .img-wrapper {
                    width: 100%;
                    margin-right: 40px;
                    padding: 24px 48px;
                    border: 1px solid #E5F1FD;
                    border-radius: 77px;
                    background-color: #E5F1FD;

                    img {
                        min-width: 239px;
                        object-fit: contain
                    }
                }
                
            }
        }

        .right-content {
            text-align: center;
            width: 100%;
            padding: 12px;

            &> * {
               margin: 18px 0; 
            }

            @media(min-width: 576px) {
                width: auto;
                margin-left: 40px;
                padding: 0;
                text-align: left;
            }

            p {
                font-size: 1rem;
                font-weight: bold;
                color: #063B58;
            }

            .disclaimer {
                color: #8CB6DB;
            }
        }

        .mobile-img {
            @media(min-width: 576px) {
                display: none;
            }
        }

        .header-text,.header-content {
            font-weight: 700;
            font-size: 1.8rem;
            @media(min-width: 576px) {
                font-size: 3rem;
            }
 
        }

        .header-text {
            color: #063B58;
        }

        .header-content {
            color: #8CB6DB;
        }

    }

    .form-inline {
        padding-top: 12px;
        justify-content: space-between;

        @media(max-width: 575px) {
            flex-direction: column;
        }
    }

    .btn {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        background-color: #063B58;
        border-radius: 11px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 24px;

        &:hover {
            color: #ffffff;
            outline: none;
        }
    }

</style>
