<template>
  <div class="page-loader" v-if="!isloaded">
    <div class="topImg"><img class="pachiLogo" src="@/assets/888.png" alt="Pachi" /></div>
    <div class="circle-container">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isloaded: false,
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isloaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/loadingscreen.scss";
</style>
