<template>
  <div>
    <HeaderLogin />

            <section class="container-fluid">
                <div class="page-header">
                    <h2 class="header-text">
                        CREATE MY ACCOUNT
                    </h2>
                    <p class="header-content">
                        Ensure your details are correct and match the details on your Pachi+ account.
                    </p>
                </div>

                <section class="container-fluid details">
                   <form class="form" action="/verify-email">
                        <div class="form-group">
                            <h2 class="header col-md-3 offset-md-2">
                                Personal Details
                            </h2>
                            <div class="details-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                :icon="['far', 'user-circle']"
                                class="content-icon"
                                />
                                <input
                                    type="text"
                                    class="form-control"
                                    id="firstName"
                                    placeholder="First Name / Last Name"
                                    required
                                    v-model="user.fullName"
                                />
                            </div>
                            <div class="details-group col-md-3  offset-md-2 no-icon">
                                <select
                                    class="form-control"
                                    name="gender"
                                    id="gender"
                                    v-model="user.genderID"
                                    required
                                >
                                    <option
                                    v-for="option in options.genderoption"
                                        :value="option.value"
                                    v-bind:key="option.value"
                                    >
                                    {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="details-group col-md-3 offset-md-2 with-label">
                                <font-awesome-icon
                                icon="birthday-cake"
                                class="content-icon"
                                />
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="birthdate"
                                        placeholder="Birthdate(yyyy-mm-dd)"
                                        data-provide="datepicker"
                                        v-model="user.dateOfBirth"
                                        required
                                    />
                                    <label class="mobile-only">Birthdate</label>
                            </div>
                            <div class="details-group col-md-6 offset-md-2">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="confirmCheck"
                                        v-model="termsCheck"
                                        required
                                    />
                                    <label class="form-check-label lbl2" for="confirmCheck"
                                        >I confirm that I am 18 years of age and have read and
                                        agreed to the EULA. I do not have any existing accounts and
                                        understand that all duplicate accounts will be
                                        cancelled.</label
                                    >
                                </div>
                            </div>
                            <div class="details-group col-md-6 offset-md-2">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="understandCheck"
                                        v-model="disclaimerCheck"
                                        required
                                    />
                                    <label class="form-check-label" for="understandCheck"
                                        >I understand real prize competitions are permitted US and
                                        Canada - the exceptions being Arizona, Arkansas,
                                        Connecticut, Delaware, Louisiana, Montana, South Carolina,
                                        South Dakota, Tennessee and Quebec. Our virtual currency
                                        games are available globally.
                                    </label>
                                </div>
                            </div>
                            <div class="details-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                icon="mobile-alt"
                                class="content-icon mobile-icon"
                                />
                                    <input
                                        type="text"
                                        @keypress="onlyNumberKey($event)"
                                        class="form-control"
                                        placeholder="phone number"
                                        id="number"
                                        v-model="user.phoneNumber"
                                    />
                            </div>
                            <div class="details-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                    :icon="['far', 'envelope']"
                                    class="content-icon"
                                />
                                <input
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    placeholder="Email"
                                    required
                                    v-model="user.emailAddress"
                                />
                            </div>
                            <div class="details-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                icon="globe"
                                class="content-icon"
                                />
                                <select
                                    class="form-control"
                                    name="country"
                                    id="country"
                                    required
                                        v-model="user.countryID"
                                >
                                    <option value="" disabled selected hidden>Country</option>
                                    <option
                                        v-for="option in countryoption"
                                            :value="option.id"
                                        v-bind:key="option.id"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <h2 class="header header col-md-3 offset-md-2">
                                Login Details
                            </h2>
                            <div class="details-group col-md-3 offset-md-2">
                                <font-awesome-icon icon="user" class="content-icon" />
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Display Name"
                                    id="username"
                                    v-model="user.displayName"
                                    required
                                />
                            </div>
                            <div class="details-group col-md-3 offset-md-2">
                                <font-awesome-icon icon="lock" class="content-icon" />
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Password"
                                    id="password"
                                    v-model="pass"
                                    required
                                />
                            </div>
                            <div class="details-group col-md-3 no-icon offset-md-2">
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Confirm Passworrd"
                                    v-model="confirmPass"
                                    id="password2"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <h2 class="header header col-md-3 offset-md-2">
                                Shipping Details
                            </h2>
                            <div class="details-group col-md-6 offset-md-2 shipping-info">
                                <div class="input-group">
                                    <font-awesome-icon
                                        icon="user-circle"
                                        class="content-icon"
                                        />
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="shipFirstName"
                                        placeholder="First Name"
                                        required
                                        v-model="user.firstname"
                                    />
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="shipLastName"
                                        placeholder="Last Name"
                                        required
                                        v-model="user.lastname"
                                    />
                                </div>
                            </div>
                            <div class="details-group address-info col-md-9 offset-md-2">
                                <div class="input-group">
                                    <font-awesome-icon icon="map-marker-alt" class="content-icon address-icon" />
                                    <input
                                        type="text"
                                        class="form-control col-md-3"
                                        id="addrline1"
                                        placeholder="Address Line 1"
                                        v-model="user.shippingaddress1"
                                    />
                                    <input
                                        type="text"
                                        class="form-control col-md-3"
                                        id="addrline2"
                                        placeholder="Address Line 2"
                                        v-model="user.shippingaddress2"
                                    />
                                    <input
                                        type="text"
                                        class="form-control col-md-3"
                                        id="city"
                                        placeholder="City"
                                        v-model="user.city"
                                    />
                                </div>
                            </div>
                            <div class="details-group address-info second-column col-md-9 offset-md-2">
                                <div class="input-group">
                                    <select class="form-control col-md-3" name="Country" id="country2" v-model="user.shippingcountryid">
                                        <option value="" disabled selected hidden>Country</option>
                                        <option
                                            v-for="item in shipcountryoption"
                                            :value="item.id"
                                            v-bind:key="item.id"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <input
                                        type="text"
                                        name="PostalCode"
                                        placeholder="Postal Code"
                                        size="33"
                                        id="postalCode"
                                        class="form-control"
                                        maxlength="32"
                                        v-model="user.postalcode"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div class="details-group submit">
                            <input
                                type="submit"
                                class="btn"
                                value="Submit"
                                id="submitBtn"
                                @click.prevent="RegisterUser"
                            />
                            <div v-if="alert.message" :class="`alert ${alert.type}`">
                                {{ alert.message}}
                            </div>
                        </div>
                    </form>
                </section>
            </section>

       <Footer />
    </div>
</template>

<script>
import { apiurl } from "../server.config";
// const token = localStorage.getItem("token");
import axios from "axios";
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import HeaderLogin from "../components/HeaderLogin";
import Footer from "../components/Footer";
export default {
  name: "Register",
  components: {
    HeaderLogin,
    Footer,
  },
  data() {
    return {
      user: {
        fullName: "",
        displayName: "",
        password: "",
        dateOfBirth: "",
        genderID: 1,
        countryID: "",
        emailAddress: "",
        phoneNumber: "",
        age_confirmation: 0,
        permitConfirmation: 0,
        shippingaddress1: "",
        shippingaddress2: "",
        city: "",
        shippingcountryid: 1,
        postalcode: "",
        firstname: "",
        lastname: "",
      },
        termsCheck: false,
      disclaimerCheck: false,
      pass:"",
      confirmPass:"",
      countryoption: [],
      shipcountryoption: [],
      options: {
        genderoption: [
          {
            value: 1,
            label: "Male",
          },
          {
            value: 2,
            label: "Female",
          },
          {
            value: 3,
            label: "Disclose",
          },
        ],
        countryoption: [],
        shipcountryoption: [],
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
   created() {
     this.getCountry();
   },
  methods: {
    ...mapMutations("alert", ['success', 'error', 'clearAlert']),
    ...mapActions("account", ["register"]),
    async getCountry() {
      const headers = {
        "Content-Type": "application/json",
      };
      try {
        const { data } = await axios.get(apiurl + "exchange/displaycountry", { headers })
        this.countryoption = [...data]
        this.shipcountryoption = [...data]
      } catch(e) {
          console.error(e)
      }
    },
    onlyNumberKey(evt) {
        var charCode = evt.which ? evt.which : evt.keyCode;
        const isDigits = charCode >= 48 && charCode <= 57
      if (!isDigits && charCode !== 43 /* plus sign */) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkIf18Years() {
        const birthdate = moment(this.user.dateOfBirth)

        return moment().diff(birthdate, 'years') >= 18
    },
    RegisterUser() {
        this.clearAlert();
        const { emailAddress, fullName, displayName, dateOfBirth } = this.user
        if(!emailAddress || !fullName || !displayName || !dateOfBirth || !this.pass){
            this.error('Please fill up required fields');
            return;
        }

        if (!this.termsCheck || !this.disclaimerCheck) {
            this.error('Please agree to the terms and disclaimer.')
            return;
        }

        if(this.pass.length < 6) {
            this.error('Password should be more than six characters in length.')
            return;
        }

        if (!this.confirmPass || this.pass !== this.confirmPass) {
            this.error('Passwords do not match.')
            return;
        }

        if (!this.checkIf18Years()) {
            this.error('Please check your birthday input. You must be 18 years or older to register.')
            return;
        }

        this.user.password = this.pass;
        this.user.age_confirmation = 1;
        this.user.permitConfirmation = 1;
        this.user.dateOfBirth = moment(this.user.dateOfBirth).format('YYYY-MM-DD')

        this.register(this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
    color:#034B73;
    display: flex;
    justify-content: center;
    font-family: Arial;
    font-weight: 700;
    align-items: center;
    flex-direction: column;
    margin-bottom: 22px;
    margin-top: 42px;
    padding: 0 12px;
    .header-text {
        line-height: 52px;
        margin: auto;
        margin-bottom: 0;
        font-size: 2rem;

        @media (min-width: 576px) {
            margin: 0;
            font-size: 2.5rem;
        }
    }

    .header-content {
        font-size: 1rem;

        @media (min-width: 576px) {
            font-size: 1.3rem;
        }
    }
}

.container-fluid {
    padding: 0;
}

.form {
    color: white;
    background: #034B73;
    padding: 12px;
    @media (min-width: 576px) {
        padding: 24px;
    }
    .header {
        margin-top: 24px;
        font-size: 1.5rem;
        @media(min-width: 576px) {
            font-size: 2rem;
        }
    }

    .content-icon {
        display: none;

        @media (min-width: 576px) {
            display: block;
            font-size: 2rem;
            margin-right: 12px;

            &.mobile-icon {
                margin-left: 5px;
                margin-right: 17px;
            }

            &.address-icon {
                margin-left: 4px;
                margin-right: 16px;
            }
        }
    }

    .details-group {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;

        &.with-label {
            flex-direction: column;

            .mobile-only {
                margin-bottom: 0;
            }

            @media (min-width: 576px) {
                flex-direction: row;
                .mobile-only {
                    display: none;
                }
            }
        }

        &.no-icon {
            @media (min-width:576px) {
                padding-left: calc(2rem + 24px);
            }
        }

        .form-control {
            &:not(:last-child) {
                margin-bottom: 12px;

                @media (min-width: 576px) {
                    margin-right: 12px;
                }
            }
        }

        #birthdate {
            margin-right: 0;
            margin-bottom: 0;
        }

        &.shipping-info, &.address-info {
            .form-control {
                width: 100%;
                @media (min-width: 576px) {
                    max-width: 200px;
                    margin-bottom: 12px;
                }
           }

        }
        &.second-column {
            .form-control:first-child {
                @media (min-width: 576px) {
                    margin-left: 44px;
                }
            }
        }
    }
}

.submit {
    justify-content: center;
    align-items: center;
    flex-direction: column !important;


    .btn {
        margin-top: 16px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        color: #063B58;
        background-color: #fff;
        border-radius: 11px;
        text-align: center;
        padding: 12px 24px;
        margin-bottom: 16px;
        max-width: 125px;

        &:hover {
            color: white;
            outline: none;
        }
    }

    .alert {
        width: 100%;
    }
}

.mobile-only {
    display: block;
    @media (min-width: 576px) {
        display: none;
    }
}

.container-fluid {
    padding: 0;
}

.form-group {
    margin-bottom: 24px;

    h2 {
        text-align: center;

        @media (min-width: 576px) {
            text-align: left;
        }
    }
}

.form-check {
    label {
        text-align: left;
    }
}
</style>
