import { userService } from '../services';
import { router } from '../router';
import { pachiuser, pachipassword } from "../server.config";

const user = JSON.parse(localStorage.getItem('user'));
const token = localStorage.getItem('token');

const state =
    user ? { status: { loggedIn: true, token: token }, user } : { status: {}, user: null, token: null };


const actions = {
    async login({ dispatch, commit }, { username, password }) {
        commit('auth_request');
        return userService.authenticate(pachiuser, pachipassword).then(
            token => {
                commit('auth_success', { token, username });
                commit('loginRequest', { username });
                return userService.login(username, password, token)
                    .then(
                        user => {
                            if (user.result === "SUCCESS") {
                                commit('loginSuccess', user, token);
                                return user.result
                            } else {
                                commit('loginFailure', user.result);
                                dispatch('alert/error', "Incorrect username or password.", { root: true });
                            }

                        },
                        error => {
                            commit('loginFailure', error);
                            dispatch('alert/error', error, { root: true });
                        }
                    );
            });
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },

    register({ dispatch, commit }, user) {
        commit('registerRequest', user);
        commit('auth_request');
        userService.authenticate('pachi-exapi', 'expachi').then(
            token => {
                commit('auth_success', { token, user });
                userService.register(user)
                    .then(
                        data => {
                            if (data.result === "RECORD INSERTED") {
                                commit('registerSuccess', user);
                                router.push('/welcome');
                                // display success message after route change completes
                                dispatch('alert/success', 'Registration successful', { root: true });

                            } else {
                                commit('registerFailure', data.result);
                                dispatch('alert/error', data.result, { root: true });
                            }

                        },
                        error => {
                            commit('registerFailure', error);
                            dispatch('alert/error', error, { root: true });
                        }
                    );
            });
    },
    update({ dispatch, commit }, user) {
        commit('updateUserRequest', user);
        userService.update(user)
            .then(data => {
                if (data.result == "RECORD UPDATED") {
                    commit('updateUserSuccess', data);
                    dispatch('alert/success', 'Account update successful', { root: true });
                    router.push('/my-account');
                } else {
                    commit('updateUserFailure', data.result);
                    dispatch('alert/error', data.result, { root: true });
                }
            },
                error => {
                    commit('updateUserFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, token, user) {
        state.status = { loggedIn: true, token: token };
        state.user = user;
    },
    auth_error(state) {
        state.status = 'error'
    },
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user, token) {
        state.status = { loggedIn: true, token: token };
        state.user = user;
        state.token = token;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
        state.token = null
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    updateUserRequest(state) {
        state.updateuser = { loading: true };
    },
    updateUserSuccess(state, user) {
        state.updateuser = user
    },
    updateUserFailure(state) {
        state.status = {};
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default account