const getExchangeContent = (code, name) => {
    const exchangeContent = {
        amazon: {
            title: 'Amazon.com Gift Card',
            content: [
                'Shop on Amazon.com with your Amazon.com Gift Card. This gift card will be accepted in the countries available with Amazon.com. This Gift Card will be delivered to your email with the nominated amount exchanged with your tickets under your name.'
            ],
            disclaimer: [
                '*Please allow 5-10 working days to process your Gift Card. For security purposes, we will be required to do a level 1 verification. For security purposes and to confirm your order you will be required to verify your account. Restrictions apply, see amazon.com/gc-legal'
            ]
        },
        apple: {
            title: 'Apple Store Gift Card',
            content: [
                'Shop or use for credit in Apple Retail Store or Apple Online Store with this gift card.',
                'This Gift Card will be delivered to your email with the nominated amount exchanged with your tickets under your name.',
                'Please allow 5-10 working days to process your Gift Card. For security purposes, we will be required to do a level 1 verification. To confirm your order, Please check your nominated email for Pachi.'
            ],
            disclaimer: [
                '*This gift card can only be used in Apple Retail Store or Apple Online Store. For security purposes and to confirm your order you will be required to verify your account.'
            ]
        },
        google: {
            title: 'Google Play Gift Card',
            content: [
                'Shop in Google Play with this gift card. Download music, games, apps, e-books, movies and more.',
                'This Gift Card will be delivered to your email with the nominated amount exchanged with your tickets under your name.',
                'Please allow 5-10 working days to process your Gift Card. For security purposes, we will be required to do a level 1 verification. To confirm your order, Please check your nominated email for Pachi+.'
            ],
            disclaimer: [
                'Google Play Gift Cards are only available to use with you Google Play account and Android devices.  For security purposes and to confirm your order you will be required to verify your account.'
            ]
        },
        itunes: {
            title: 'iTunes Gift Card',
            content: [
                'Shop in iTunes with this gift card. Download music, games, apps, e-books, movies and more.',
                'This Gift Card will be delivered to your email with the nominated amount exchanged with your tickets under your name.',
                'Please allow 5-10 working days to process your Gift Card.  For security purposes, we will be required to do a level 1 verification.  To confirm your order, Please check your nominated email for Pachi.'
            ],
            disclaimer: [
                '*iTunes Gift Cards are only available to use with you iTunes account and iOS devices.  For security purposes and to confirm your order you will be required to verify your account.'
            ]
        },
        mystery: {
            title: 'Pachi Mystery Box',
            content: [
                `Redeem Your ${getMysteryBoxSubtitle(name)} Mystery Box.`,
                'Receive 1 or a few small items from Pachi Exchange.',
                'The items can range from lollies, small toys and essentials. Our team will personally select this surprise for you.',
                'The mystery box will be delivered to your confirmed home delivery address with your Pachi+ account. Please validate your address in the next step.',
                'Delivery will vary depending on your location and couriers delivery times.'
            ],
            disclaimer: [
                '*Only available in the Philippines'
            ]
        },
        premium: {
            title: 'Pachi Premium Prize',
            content: [
                'Pachi Premium Trophies are exclusive Pachi Exchange prizes that have various cash value equivalent and can be collected in your account.  You may choose to build up your trophy collection or you can choose to cash out their cash value to store them in your Pachi Wallet.'
            ]
        },
        lazada: {
            title: 'Lazada eVoucher',
            content: [
                'Shop or use for credit in Lazada Online Store with this gift card.',
                'This Gift Card will be delivered to your email with the nominated amount exchanged with your tickets under your name.',
                'Please allow 5-10 working days to process your Gift Card.  For security purposes, we will be required to do a level 1 verification.  To confirm your order, Please check your nominated email for Pachi.'
            ],
            disclaimer: [
                '*This gift card can only be used in Lazada online shopping website.  For security purposes and to confirm your order you will be required to verify your account.'
            ]
        },
        puregold: {
            title: 'Puregold eVoucher',
            content: [
                'Shop or use for credit in Puregold Online Store with this gift card.',
                'This gift card will be delivered to your emil with the nominated amount exchanged with your tickets under your name.',
                'Please allow 5-10 working days to process your gift card. For security purposes, we will be required to do a level 1 verification. To confirm your order, please check your nominated email address for Pachi.'
            ],
            disclaimer: [
                '*This gift card can only be used in the Puregold online shopping website. For security purposes and to confirm your order, you will be required to verify your account.'
            ]
        }
    }

    if (isPremiumItems(code)) {
        return exchangeContent.premium
    }
    return exchangeContent[code.toLowerCase()]
}



export function isPremiumItems(item) {
    const premiumItems = ['lucky cat', 'koi', 'ingot', 'fortune cookie', 'dragon', 'buddha'];
    return premiumItems.includes(item.toLowerCase())
}

export function getMysteryBoxSubtitle(item) {
    const boxes = {
        MysteryBox20: 'Small',
        MysteryBox50: 'Medium',
        MysteryBox100: 'Large'
    }
    return boxes[item.replace(/\s+/g, '')]
}

export function populateData({ code, name }) {
    return getExchangeContent(code, name)
}