<template>
  <div>
    <Header />

    <section class="container-fluid">
        <Stepper :stepperItems="['Review Items', 'Digital Delivery', 'Exchange']" :step="3"/>
        <div class="page-header">
            <h2 class="header-text">CONGRATULATIONS</h2>
            <p class="header-content">Your gift card delivery is being processed!</p>
            <p class="header-content">Your prize will be emailed within 5-10 working days.</p>
        </div>
        <section class="cta">
                <div class="cta-wrapper">
                <div class="img-wrapper">
                    <img src="@/assets/phone.png" />
                </div>
                <div class="cta-text">
                    <h4>KEEP PLAYING PACHI+,</h4>
                    <h4>KEEP COLLECTING TICKETS</h4>
                </div>
                <div class="cta-button">
                    <a role="button" class="btn" href="https://play.pachiplus.com">PLAY GAME ON BROWSER</a>
                </div>
            </div>
        </section>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from "../components/Stepper.vue"
export default {
  name: "ProductConfirmation",
  components: {
    Header,
    Footer,
    Stepper
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
    padding: 0;
}

.page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0 12px;
    color:#063B58;

    & > *{
      margin-bottom: 12px;  
    }
    .header-text {
   
        line-height: 52px;
        font-size: 2rem;

        @media (min-width: 576px) {
            margin-top: 32px;
            font-size: 3rem;
        }
    }

    .header-content {
        font-size: 1rem;

        @media (min-width: 576px) {
            font-size: 1.5rem;
        }
    }
}

.cta {
    padding: 0 24px;
}

.cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 20px;
    background-color: #E5F1FD;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
    color: #063B58;
    div {
        margin: 12px 0;
    }

    .img-wrapper {
        order: 1;

        @media (min-width: 576px) {
            order: 0;
        }
    }

    .cta-button {
        order: 2;
    } 

    @media (min-width: 576px) {
        flex-direction: row;

        div {
            margin: 0 12px;
        }
    }
}

.btn {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    background-color: #063B58;
    border-radius: 11px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 24px;
    max-width: 210px;
}
</style>
