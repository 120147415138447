import { render, staticRenderFns } from "./TrophyBuddha.vue?vue&type=template&id=4da3ab93&scoped=true&"
import script from "./TrophyBuddha.vue?vue&type=script&lang=js&"
export * from "./TrophyBuddha.vue?vue&type=script&lang=js&"
import style0 from "./TrophyBuddha.vue?vue&type=style&index=0&id=4da3ab93&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da3ab93",
  null
  
)

export default component.exports