mobile<template>
  <div>
    <Header />

    <div class="desktopPrize">
      <div class="exch" id="exch">
        <div class="container">
          <div class="row">
            <div class="col-5">
              <i><img class="imagesize" src="@/assets/prizeamazon20.png"/></i>
            </div>
            <div class="right-content col-7">
              <h3>Amazon.com Gift Card</h3>
              <h3 class="cat">$20.00</h3>
              <p class="small">
                Shop on Amazon.com with your Amazon.com Gift Card. This gift
                card will be accepted in the countries available with
                Amazon.com. This Gift Card will be delivered to your email with
                the nominated amount exchanged with your tickets under your name
              </p>
              <p class="smal">
                *Please allow 3-5 working days to process your Gift Card. For
                security purposes, we will be required to do a level 1
                verification. To confirm your order, Please check your nominated
                email for Pachi. Restrictions apply, see
                <a href="https://amazon.com/gc-legal" target="_blank"
                  >amazon.com/gc-legal</a
                >
              </p>
              <form action="/product/amazon/verify-email">
                <div class="form-group">
                  <label class="qty" for="qty">QTY:</label>
                  <input type="text" id="qty" name="qty" /><br /><br />
                </div>
                <div class="form-group">
                  <label class="tickets" for="tickets">Tickets:</label>
                  <input type="text" id="tickets" name="tickets" />
                </div>
                <input class="add" type="submit" value="CONTINUE" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobilePrize">
      <div class="container">
        <h3>Amazon.com Gift Card</h3>
        <h3 class="cat">$20.00</h3>
        <div class="mobileImage">
          <i><img class="imagesize" src="@/assets/prizeamazon20.png"/></i>
        </div>
        <div class="mobileContent">
          <p class="mobilesmall">
            Shop on Amazon.com with your Amazon.com Gift Card. This gift card
            will be accepted in the countries available with Amazon.com. This
            Gift Card will be delivered to your email with the nominated amount
            exchanged with your tickets under your name
          </p>
          <p class="mobilesmal">
            *Please allow 3-5 working days to process your Gift Card. For
            security purposes, we will be required to do a level 1 verification.
            To confirm your order, Please check your nominated email for Pachi.
            Restrictions apply, see
            <a href="https://amazon.com/gc-legal" target="_blank"
              >amazon.com/gc-legal</a
            >
          </p>
          <form action="/product/amazon/verify-email">
            <div class="form-group">
              <label class="qty" for="qtymobile">QTY:</label>
              <input type="text" id="qtymobile" name="qty" /><br /><br />
            </div>
            <div class="form-group">
              <label class="ticket" for="ticketsmobile">Tickets:</label>
              <input type="text" id="ticketsmobile" name="tickets" />
            </div>
            <div class="form-group">
            <input class="add" type="submit" value="CONTINUE" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "Amazon20",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/giftcards.scss";
</style>
