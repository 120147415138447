<template>
<div>
    <section class="carousel-desktop">
            <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="40000"
            controls
            background="rgb(171 171 171 / 0%)"
            img-width="1600"
            img-height="900"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            >
            <b-carousel-slide>
                <template #img>
                <div class="item">
                    <a href="https://pachiplus.com">
                        <img class="img-fluid" src="@/assets/carousel/banner_1.png" alt="slider image" style="width:100%">
                    </a>
                </div>
               </template>
            </b-carousel-slide>
            <b-carousel-slide>
                <template #img>
                <div class="item">
                    <a href="/shop">
                        <img class="img-fluid" src="@/assets/carousel/banner_2.png" alt="slider image" style="width:100%">
                    </a>
                </div>
               </template>
            </b-carousel-slide>
            <b-carousel-slide>
                <template #img>
                <div class="item">
                    <a href="/sweepstakes">
                        <img class="img-fluid" src="@/assets/carousel/banner_3.png" alt="slider image" style="width:100%">
                    </a>
                </div>
               </template>
            </b-carousel-slide>
            <b-carousel-slide>
                <template #img>
                 <div class="item">
                    <a href="https://play.pachiplus.com">
                        <img class="img-fluid" src="@/assets/carousel/banner_4.png" alt="slider image" style="width:100%">
                    </a>
                </div>
               </template>
            </b-carousel-slide>
             <!-- <b-carousel-slide>
                <template #img>
                 <div class="item">
                    <a href="https://play.pachiplus.com">
                        <img class="img-fluid" src="@/assets/carousel/banner_5.png" alt="slider image" style="width:100%">
                    </a>
                </div>
               </template>
            </b-carousel-slide> -->
        </b-carousel>
        </section>

        <section class="carousel-mobile">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="40000"
          background="rgb(171 171 171 / 0%)"
          img-width="376"
          img-height="399"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide>
            <template #img>
                <div class="item">
                    <a href="https://pachiplus.com">
                        <img class="img-fluid" src="@/assets/carousel/mobile-banner_1.png" alt="slider image" style="width:100%">
                    </a>
                </div>
           </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="item">
                    <a href="/pachi-shop">
                        <img class="img-fluid" src="@/assets/carousel/mobile-banner_2.png" alt="slider image" style="width:100%">
                    </a>
                </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="item">
                    <a href="/sweepstakes">
                        <img class="img-fluid" src="@/assets/carousel/mobile-banner_3.png" alt="slider image" style="width:100%">
                    </a>
                </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="item">
                    <a href="https://pachiplus.com">
                        <img class="img-fluid" src="@/assets/carousel/mobile-banner_4.png" alt="slider image" style="width:100%">
                    </a>
                </div>
            </template>
          </b-carousel-slide>
          <!-- <b-carousel-slide>
            <template #img>
              <div class="item">
                    <a href="https://pachiplus.com">
                        <img class="img-fluid" src="@/assets/carousel/mobile-banner_5.png" alt="slider image" style="width:100%">
                    </a>
                </div>
            </template>
          </b-carousel-slide> -->
        </b-carousel>
      </section>
</div>
</template>

<script>
export default {
    name: 'Carousel',
    data() {
        return {
        slide: 0,
        sliding: null,
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },
    }
    }
</script>

<style lang="scss" scoped>
.carousel-desktop {
    @media (max-width: 576px) {
        display: none;
    }

    .carousel {
        margin-top: -100px;
        margin-bottom: 50px;
    }

    .item {
        margin: auto;
        max-width: 1460px;
        width: 85%;
    }

    .card {
        border: none;
        border-radius: 0;

        .card-title {
            margin-top: 3rem;
            font-weight: bold;
            font-size: 48px;
            width: 85%;
            margin-left: auto;
            margin-right: auto;
        }

        .card-text {
            margin-bottom: 3rem;
        }
    }
    
}

.carousel-mobile {
    @media (min-width: 576px) {
        display: none;
    }

    .carousel {
        margin: 0;
    }

    .item {
        margin: auto;
        max-width: 375px;
        max-height: 399px;
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

::v-deep .carousel-control-prev,
::v-deep .carousel-control-next {
    opacity: 100%;
}

::v-deep .carousel-control-prev-icon,
  ::v-deep .carousel-control-next-icon {
    background-image: none;
    background-size: 100%, 100%;
    opacity: 100%;
    display:none;
}



::v-deep .carousel-control-next-icon::after {
    color: #FFFFFF;
    content: '\003E';
    font-size: 55px;
 
}
 ::v-deep .carousel-control-prev-icon::after {
    color: #FFFFFF;
    content: '\003C';
    font-size: 55px;
 }

</style>