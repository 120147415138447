<template>
  <div>
    <Header />

    <section class="container-fluid">
        <Stepper  :stepperItems="['Confirm Amount', 'Payment Option', 'Confirm Cashout']" :step="2" />
        <div class="page-header">
            <h2 class="header-text">
                Choose your payment option
            </h2>
        </div>
        <div v-if="alert.message" :class="`alert ${alert.type}`" role="alert">
            {{ alert.message }}
        </div>
        <div class="options-container">
            <div class="btn-container" v-for="option in paymentOptions" :key="option.type">
                <button :class="{'btn btn-bordered': true, 'disabled': option.isDisabled}" @click.prevent="handleSelectedPayment(option.type)">
                    {{ option.name }}
                </button>
            </div>
        </div>
        <b-modal v-model="showConfirmationModal" centered hide-footer hide-header-close>
            <template #modal-title>
                You are about to cash out
            </template>
            <div class="d-block text-center">
                <p>*Minimum cashout amount is $100.00</p>
                <input type="text" :value="`$${isNaN(Number($props.cashoutAmount)) ? `0.00` : Number($props.cashoutAmount).toFixed(2)}`" disabled/>
                <div class="btn-container">
                    <button class="btn" @click.prevent="showConfirmationModal = !showConfirmationModal">
                        Cancel
                    </button>
                    <button class="btn" @click.prevent="InsertCashoutRequest(selectedPaymentType)">
                        Proceed
                    </button>
                </div>

            </div>
        </b-modal>
    </section>

    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
const token = localStorage.getItem("token");
import { mapState } from "vuex";
import axios from "axios";
import { router } from "../router";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from '../components/Stepper.vue'
export default {
  name: "CashoutPayment",
  components: {
    Header,
    Footer,
    Stepper
  },
  props: ['cashoutAmount'],
  data() {
    return {
        selectedPaymentType: null,
        showConfirmationModal: false,
        tickets: 0,
        cashout: 0,
        paymentOptions: [
            {
                name: 'BSB/ACCOUNT',
                type: 4,
                isDisabled: false
            },
            {
                name: 'PAYPAL',
                type: 1,
                isDisabled: false
            },
            {
                name: 'STRIPE',
                type: 2,
                isDisabled: true
            },
            {
                name: 'AIRWALLEX',
                type: 3,
                isDisabled: true
            }
        ]
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
    }),
  },created() {
    this.getUser();
  },
  methods: {
      handleSelectedPayment(type) {
          if ([1,4].includes(type)) {
              this.selectedPaymentType = type;
              this.showConfirmationModal = !this.showConfirmationModal;
          }
          else {
            this.InsertCashoutRequest(type)
          }
      },
    InsertCashoutRequest(paymentType) {
        let cashoutAmount = this.$props.cashoutAmount;
        if(this.cashout >= cashoutAmount){
            router.push({
                name: 'Payment Details',
                params: {
                    paymentType,
                    cashoutAmount
                }
            })
            } else{
                alert("Invalid cashout amount");
        }
    },
    getUser() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const id = this.account.user.data.id;
      axios
        .get(apiurl + "exchange/myuserwithdetails/" + id, { headers: headers })
        .then((response) => {
          this.cashout = response.data.cashoutTotal == null ? 0 : response.data.cashoutTotal;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/cashoutpayment.scss";

.container-fluid {
    padding: 0;
}

.header-text {
    margin-top: 48px;
    margin-bottom: 32px;
    line-height: 52px;
    font-size: 2rem;

    @media (min-width: 576px) {
        font-size: 3rem;
    }
}

.options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-container {
        margin-top: 12px;
    }

    @media (min-width: 576px) {
        flex-direction: row;

        .btn-container {
            margin-left: 8px;
            &:not(:last-child) {
                margin-right: 8px;
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.btn {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: #063B58;
    border-radius: 25px;
    text-align: center;
    padding: 12px 24px;

    &.btn-bordered {
        color: #063B58;
        border: 4px solid #063B58;
        background: white;
    }

    &:hover {
        color: #034B73;
        outline: none;
    }

    &.disabled {
        border: 4px solid #8CB6DB;
        cursor: not-allowed;
        pointer-events: none;
    }
}

::v-deep .c-stepper__item {
    &:last-child {
        h3 {
            margin-top: 2px;
        }
    }
}

::v-deep .modal-header {
    background-color: #063B58;
    color: white;
    align-items: center;
    justify-content: center;

    .modal-title {
        font-size: 1.5rem;
    }
}

::v-deep .modal-body {
    background: #e5f1fd;

    padding-top: 0;
    color: #063B58;
    font-weight: bold;

    input[type="text"] {
        color: #063B58;
        border: none;
        border-radius: 10px;
        background-color: white;
        font-size: 1.3rem;
        font-weight: bold;
        max-width: 40%;
        text-align: center;
        padding: 8px 4px;
        margin-bottom: 1rem;
    }

    .btn {
        margin: 0 8px;
        border-radius: 10px;
        background: #063B58;
    }
}
</style>
