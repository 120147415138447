<template>
  <div>
    <HeaderLogin />

    <div class="container">
      <h1>This Page Isn't Available</h1>
      <h4>
        The link may be broken, or the page may have been removed. Check to see
        if the link you're trying to open is correct.
      </h4>
      <h5>
          <a href="/home">Click here to go back to home.</a>
      </h5>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeaderLogin from "../components/HeaderLogin";
import Footer from "../components/Footer";
export default {
  name: "PageNotFound",
  components: {
    HeaderLogin,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/pagenotfound.scss";
</style>
