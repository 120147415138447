<template>
  <div>
    <Header />

    <section class="container">
        <div class="header">
            <h2 class="header-text">My Account</h2>
        </div>
        <div v-if="alert.message" :class="`alert ${alert.type}`">
            {{ alert.message}}
        </div>
        <section class="info">
            <div class="left-content">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            Personal Details
                        </h5>
                        <div class="detail">
                            <font-awesome-icon
                            :icon="['far', 'user-circle']"
                            class="content-icon"
                            /> {{ user.fullName }}
                        </div>
                        <div class="detail">
                            <font-awesome-icon
                            icon="mobile-alt"
                            class="content-icon mobile-icon"
                            /> {{ user.phoneNumber }}
                        </div>
                        <div class="detail">
                            <font-awesome-icon
                            :icon="['far', 'envelope']"
                            class="content-icon"
                            /> {{ user.emailAddress }}
                        </div>
                        <button class="btn" @click="editDetails">edit</button>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            Shipping Details
                        </h5>
                        <div class="detail">
                            <font-awesome-icon
                            :icon="['far', 'user-circle']"
                            class="content-icon"
                            /> {{ user.fullName }}
                        </div>
                       <div class="detail">
                            <font-awesome-icon
                            icon="map-marker-alt"
                            class="content-icon address-icon"
                            /> {{ this.user.shippingaddress1 }}&nbsp;{{ this.user.shippingaddress2 }}
                        </div>
                    </div>
                </div>

                <div class="collection">
                    <h5 class="title">
                        My Premium Prize Collection
                    </h5>

                    <div class="collection-grid">
                        <div class="card collection-card" v-for="item in myCollection" :key="item.id" @click="redeemItem(item.exchangeId, item.id)">
                            <div style="position: relative;">
                                <span class="banner" v-if="item.itemcount > 1">x{{item.itemcount}}</span>
                                <img clas="card-img-top" :src="getImgUrl(item.imagebig)" />
                            </div>
                            <button class="btn-redeem redeem-btn">redeem</button>
                        </div>
                    </div>
                </div>

                <div class="card sweepstakes-mobile">
                    <div class="card-body">
                        <h5 class="card-title">
                            Sweepstakes
                        </h5>
                        <div class="table-wrapper">
                            <table class="table borderless">
                                <thead class="thead">
                                    <tr>
                                        <th colspan="3">
                                            Entry Log
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr scope="row" class="table-headers">
                                        <td>Entry ID</td>
                                        <td>Entry Type</td>
                                        <td>Draw Date</td>
                                    </tr>
                                    <tr scope="row" v-for="item in lottery" v-bind:key="item.entryID">
                                        <td>{{ item.entryID }}</td>
                                        <td>{{ item.lotteryTypeDesc }}</td>
                                        <td>{{ item.drawDate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button class="btn" @click="redirectSweeptakes">
                            enter more
                        </button>
                    </div>
                </div>
                <div class="card rewards-mobile">
                    <div class="card-body">
                        <h5 class="card-title">
                            Rewards and Prizes
                        </h5>
                        <div class="table-wrapper">
                            <table class="table borderless">
                                <thead class="thead">
                                    <tr>
                                        <th colspan="3">
                                            Reward Log
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr scope="row" class="table-headers">
                                        <td>Reward</td>
                                        <td>Date</td>
                                        <td>Status</td>
                                    </tr>
                                    <tr scope="row" v-for="item in rewardslogs" v-bind:key="item.id">
                                        <td>{{ item.exchangeitem }}</td>
                                        <td>{{ item.lastmodifieddate }}</td>
                                        <td>{{ item.itemstatus }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <a href="/shop" role="button" class="btn">
                            exchange more
                        </a>
                    </div>
                </div>
            </div>
            <div class="right-content">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            Cash Value (USD)
                        </h5>
                        <input type="text" readonly="readonly" name="cashValue" :value="getUserCashValue" />
                        <button class="btn" @click="validateCashOut">
                            Cash out
                        </button>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            Pachi Tickets
                        </h5>
                        <img class="card-img" src="@/assets/pachiticket.png" />
                        <input type="text" readonly="readonly" name="tickets" :value="user.tickets" />
                        
                    </div>
                </div>
                <div class="card sweepstakes-desktop">
                    <div class="card-body">
                        <h5 class="card-title">
                            Sweepstakes
                        </h5>
                        <div class="table-wrapper">
                            <table class="table borderless">
                                <thead class="thead">
                                    <tr>
                                        <th colspan="3">
                                            Entry Log
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr scope="row" class="table-headers">
                                        <td>Entry ID</td>
                                        <td>Entry Type</td>
                                        <td>Draw Date</td>
                                    </tr>
                                    <tr scope="row" v-for="item in lottery" v-bind:key="item.entryID">
                                        <td>{{ item.entryID }}</td>
                                        <td>{{ item.lotteryTypeDesc }}</td>
                                        <td>{{ item.drawDate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button class="btn" @click="redirectSweeptakes">
                        enter more
                    </button>
                </div>
                <div class="card rewards-desktop">
                    <div class="card-body">
                        <h5 class="card-title">
                            Rewards and Prizes
                        </h5>
                        <div class="table-wrapper">
                            <table class="table borderless">
                                <thead class="thead">
                                    <tr>
                                        <th colspan="3">
                                            Reward Log
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr scope="row" class="table-headers">
                                        <td>Reward</td>
                                        <td>Date</td>
                                        <td>Status</td>
                                    </tr>
                                    <tr scope="row" v-for="item in rewardslogs" v-bind:key="item.id">
                                        <td>{{ item.exchangeitem }}</td>
                                        <td>{{ item.lastmodifieddate }}</td>
                                        <td>{{ item.itemstatus }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <a href="/shop" role="button" class="btn">
                        exchange more
                    </a>
                </div>
            </div>
        </section>
        <section class="cta">
            <div class="header">
                <h2 class="header-text">
                    Get More Tickets!
                </h2>
                <p class="header-content">
                    The more you play, the more Pachi Tickets you accummulate in exchange for rewards and prizes
                </p>
            </div>
            <button class="btn-play"  @click.prevent="redirectPachiGame">
                play pachi+ now
            </button>
        </section>
    </section>

    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
import { mapState } from "vuex";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { router } from "../router";


const token = localStorage.getItem("token");
const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
};
 
export default {
  name: "MyAccount",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      usersOpened: null,
      user: {},
      lottery: [],
      rewardslogs: [],
      myCollection: [],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      userId: (state) => state.account.user.data.id
    }),
    getUserCashValue() {
        return this.user.cashout ? `$ ${this.user.cashout}` : '';
    }
  },
  
  mounted() {
    this.getUser();
    this.getMyCollection();
    this.getLottery();
    this.getrewardslogs();
  },
  methods: {
    getUser() {
      axios
        .get(apiurl + "exchange/myuserwithdetails/" + this.userId, { headers })
        .then((response) => {
          this.user = {...response.data, cashout: Number(response.data.cashoutTotal)}
        });
    },
    getMyCollection() {
        axios
        .get(apiurl + "items/mycollections/" + this.userId, { headers })
        .then((response) => {
          this.myCollection = response.data;
        });
    },
    redeemItem(exchangeid, redeemid) {
      router.push("/product/redeem/" + redeemid + "/" + exchangeid);
    },
    getLottery() {
      axios
        .get(apiurl + "lottery/lotentrylogs/" + this.userId, { headers: headers })
        .then((response) => {
          this.lottery = response.data;
        });
    },
    getrewardslogs() {
     axios
        .get(apiurl + "lottery/rewardlogs/" + this.userId, { headers: headers })
        .then((response) => {
          this.rewardslogs = response.data;
        });
    },
    editDetails() {
      router.push("/my-account/edit");
    },
    validateCashOut() {
      router.push("/cashout/amount");
    },
    getImgUrl(pic) {
      return require('../assets/shop/'+pic)
    },
    redirectPachiGame(){
      return window.location.href = "https://play.pachiplus.com/";
    },
    redirectRewards(){
        router.push("/shop");
    },
    redirectSweeptakes(){
       router.push("/sweepstakes");
    }
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/myaccount.scss";
.container {
    max-width: 1350px;
}
.header {
    padding: 32px 0;
    text-align: left;
    .header-text {
        text-transform: uppercase;
        line-height: 52px;
        font-size: 2rem;
        font-weight: 700;

        @media (min-width: 576px) {
            font-size: 3rem;
            margin-bottom: 32px;
        }
    }
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 769px) {
        flex-direction: row;
    }

    .card {
        background: #063B58;
        color: white;
        border: none;
        padding: 24px;
        align-items: flex-start;
        margin-bottom: 24px;

        &.collection-card {
            background: transparent;
            border: none;
            align-items: center;
            margin-bottom: 0;

            &:hover {
                cursor: pointer;
            }

            img {
                max-width: 190px;
            }

            .btn {
                background: #fff;
                color: #063B58;
                font-weight: 400;
                width: 190px;
            }
            .btn-redeem{
                background: #063B58;
                color: #fff;
                font-weight: 400;
                width: 190px;
            }
            
            .banner {
                height: 48px;
                width: 48px;
                color: #063B58;
                background: white;
                border-radius: 50%;
                position: absolute;
                right: 0;
                padding: 8px;
                font-size: 18px;
                font-weight: bolder;
                line-height: 30px;
                box-shadow: 3px 3px 11px 0px rgba(0,0,0,0.75);

            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: center;

            @media (min-width: 769px) {
                align-self: flex-start;
            }
        }

        .card-title {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .detail {
            margin: 16px 0;
            text-align: left;
            font-size: 1rem;
            font-weight: 700;
            display: flex;

            @media (min-width: 769px) {
                font-size: 1.4rem;
            }

            .content-icon {
                font-size: 2rem;
                margin-right: 12px;

                &.mobile-icon {
                    margin-left: 5px;
                    margin-right: 17px;
                }

                &.address-icon {
                    margin-left: 3px;
                    margin-right: 15px;
                }
            }
        }

        .card-img {
            max-width: 142px;
            margin-bottom: 16px;
        }
    }

    .title {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 16px;
        text-align: center;
        
        @media (min-width: 769px) {
            text-align: left;
        }
    }



    .left-content {
        flex: 1;
        width: 100%;
        order: 1;

        @media (min-width: 769px) {
            flex: 0 0 55%;
            margin: 0;
            max-width: 55%;
            margin: 24px 0;
            order: 0;
        }
    }

    .right-content {
        flex: 1;
        width: 100%;

        @media (min-width: 769px) {
            flex: 0 0 35%;
            margin: 0;
            margin: 24px 0;
        }

        .card, .card-body {
            align-items: center !important;
            align-self: center;
        }
    }
}

.collection-grid {
    display: flex;
    flex-direction: column;
    color: #063B58;

    @media (min-width: 769px) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
}

.btn {
    margin-top: 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #063B58;
    background-color: #ffffff;
    border-radius: 11px;
    text-align: center;
    padding: 12px 24px;

    &:hover {
        color: #034B73;
        outline: none;
    }
}

.btn-play {
    margin-top: 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    background-color: #063B58;
    border-radius: 11px;
    text-align: center;
    padding: 12px 24px;

    &:hover {
        color: #034B73;
        outline: none;
    }
}

.table-wrapper {
    min-width: 356px;
    height: 475px;
    overflow: auto;

    .borderless {

        thead {
            background: #6b6b6b;
            color: white;
            font-weight: bolder;
        }
        th {
            border: none;
            font-weight: bolder;

        }
        tbody {
            background: white;
            color: #063B58;
        }
        td {
            border: none;
        }

        tr {
            border: none;
            
        }
    }
}

.sweepstakes-desktop, .rewards-desktop {
    display: none;

    @media (min-width: 769px) {
        display: flex;
    }
}
.sweepstakes-mobile, .rewards-mobile {
    display: flex;

    

    @media (min-width: 769px) {
        display: none;
    }
}

input {
    border-radius: 5px;
    border: 0;
    color: #063B58;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
.cta {
    margin: 32px auto;
    color: #063B58;
    .header { 
        margin: auto;
        width: 50%;
        text-align: center;
        padding-bottom: 0;

        .header-content {
            font-size: 1rem;

            @media (min-width: 576px) {
                font-size: 1.5rem;
            }
        }
    }
}
</style>
