<template>
  <div class="header">
      <div class="menu">
          <header id="main">
      <nav class="navbar navbar-expand-md">
        <a href="/home"
          ><img
            id="headerlogo"
            alt="Pachi Exchange logo"
            src="@/assets/logo-white.png"
        /></a>
        <ul class="navbar-nav ml-auto navDesktop">
          <li class="nav-item">
            <a class="nav-link my-account" href="/home">Home</a>
          </li>

          <li class="nav-item">
            <a class="nav-link my-account" href="/shop">Shop</a>
          </li>

          <li class="nav-item">
            <a class="nav-link my-account" href="/sweepstakes">Sweepstakes</a>
          </li>

          <li class="nav-item">
            <a class="nav-link my-account" href="/my-account" v-if="account">{{
              account.data.displayName
            }}</a>
            <!-- <a class="nav-link my-account" href="/my-account">My Account</a>-->
          </li>
            <div class="buttons-container">
                <img
                    class="avatar"
                    src="@/assets/iconFemale.png"
                    v-if="account.data.genderid == 2"
                />
                <img class="avatar" src="@/assets/iconMale.png" v-else />
                <a class="btn" id="logoutbtn" href="/" role="button">Log Out</a>
            </div>
          
        </ul>
        <div class="navMobile">
          <Slide right noOverlay class="burgerMenu" width="200">
            <a id="home" class="navMobLinks" href="/home">
              <span>Home</span>
            </a>
            <a id="shop" class="navMobLinks" href="/shop">
              <span>Shop</span>
            </a>
            <a id="sweepstakes" class="navMobLinks" href="/sweepstakes">
              <span>Sweepstakes</span>
            </a>
            <a
              id="my-account"
              class="navMobLinks"
              href="/my-account"
              v-if="account"
            >
              <span>{{ account.data.displayName }}</span>
            </a>
            <a id="logout" class="navMobLinks" href="/">
              <span>Log Out</span>
            </a>
          </Slide>
        </div>
      </nav>
          </header>
      </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
import { mapState } from "vuex";

export default {
  name: "Header",
  components: { Slide },
  computed: {
    ...mapState({
      account: (state) => state.account.user,
      user: (state) => state.user,
    }),
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/newmain.scss";

.header {
    background-color: #063B58;
    padding: 10px 0;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 576px) {
        height: 110px;
        padding: 10px 40px;
    }

    .menu {
        width: 100%;
    }

    .logo-container {
        max-width: 200px;
        @media (min-width: 576px) {
            max-width: 283px;
        }

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .navDesktop {
        display: none;

        @media (min-width: 769px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
        }
    }

    .navMobile {
        display: block;
        
        @media (min-width: 769px) {
            display: none;
        }
    }
    ::v-deep .bm-burger-button {
        top: 15px;
        height: 20px !important;
    }

    ::v-deep .bm-cross-button {
        right: 20px !important;
        top: 35px !important;
        width: 50px !important;
        height: 50px !important;

        .bm-cross {
            height: 15px !important;
            width: 5px !important;
        }
    }

    .btn {
        font-weight: 700;
        font-size: 16px;
        color: #063B58;
        background-color: #ffffff;
        border-radius: 11px;
        padding: 13px 25px;
    }

    .nav-item {
        @media (max-width: 1140px) {
            order: 1;
            .nav-link {
                padding: 0 15px !important;
            }
        }

        @media (min-width: 1140px) {
            .nav-link {
                padding: 0 20px !important;
            }
        }

        &:last-of-type .nav-link{
                @media (max-width: 1140px) {
                    padding-right: 0 !important;
                }
        }
    }

    .buttons-container {
        @media (max-width: 1140px) {
            flex: 1 0 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }  
    }

    .nav-link {
        font-size: 20px;
        padding: 0 20px !important;
        font-weight: 700;
        color:#FFFFFF;
    }

    .avatar {
        margin-right: 40px;
    }
    .bm-burger-bars {
      background-color: #fff !important;
    }
}
</style>
