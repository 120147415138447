<template>
  <div>
    <Header />
    <section class="container-fluid">
        <section class="header">
            <div class="header-group">
                <div class="header-container">
                    <h2>
                        WE HAVE A WINNER!
                    </h2>
                    <p>
                        Keep playing Pachi+ and earn those Pachi tickets to join our regular daily, weekly, and monthly Pachi Sweepstakes draws!
                        The more entries, the more chances of winning!
                    </p>
                </div>
            </div>

            <img
                src="@/assets/banner-sweepstake-result.png"
                alt="Pachi Exchange Banner"
                class="banner desktop-banner"
            />
            <img
                src="@/assets/banner-sweepstake-result-mobile.png"
                alt="Pachi Exchange Banner"
                class="banner mobile-banner"
            />
        </section>

        <section class="winners">
            <div class="header">
                <h2 class="header-mobile">SWEEPSTAKES WINNERS</h2>
                <p class="header-content"> {{ `${lottery.label.charAt(0).toUpperCase()}${lottery.label.substring(1)}`}} Draw Results for the last 7 Draws </p>
                <div class="prize-container">
                    <img src="@/assets/star.png" />
                    <span class="label">Prize at stake: </span>
                    <span class="prize">{{lottery.prize}}</span>
                </div>
            </div>
            <div class="winners-table">
                <table class="table borderless">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Draw Number</th>
                        <th scope="col">Winner</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr  scope="row" v-for="item in lottery" v-bind:key="item.id">
                        <td>{{ item.drawdate }}</td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.displayname }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>

   </section>

    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "SweepstakesResult",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      lottery: [],
    };
  },
  created() {
    this.getlotterylogs();
  },
  methods: {
    getlotterylogs() {
        const labels = ['daily', 'weekly', 'monthly'];
        const prizes = {
            daily: '250 Tickets',
            weekly: '20.00 USD',
            monthly: '50.00 USD'
        }
        const labelIndex = labels.findIndex(e => e === this.$route.params.id);
        const token = localStorage.getItem("token");
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        };

        axios
            .get(apiurl + "lottery/lotteryresult/" + (labelIndex + 1).toString(), { headers })
            .then((response) => {
            this.lottery = {...response.data, label: labels[labelIndex], prize: prizes[labels[labelIndex]]};
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/sweepstakeresult.scss";

.container-fluid {
    padding: 0;

    .header {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .banner {
        width: 100%;
    }

    .desktop-banner {
        @media (max-width: 769px) {
            display: none;
        }
    }

    .mobile-banner {
        order: 0;
        @media (min-width: 769px) {
            display: none;
        }

    }
}

.header-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 16px 24px;
    order: 1;
    background-color: #063B58;

    @media (min-width: 1024px) {
        align-items: flex-start;
        background-color: transparent;
    }

    .header-container {
        width: 100%;
    }

    h2 {
        font-weight: bold;
        font-size: 2rem;
    }
    
   p {
        font-size: 1.5rem;
        font-weight: bold;
    }

    @media (min-width: 1024px) {
        top: 16px;
        width: 40%;
        position: absolute;
        color: white;
        left: 16px;

        h2 {
            text-align: left;
            font-size: 3rem;
            font-weight: 700;
            margin-bottom: 16px;
        }
 
        p {
            text-align: left;
        }
    }

    @media (min-width: 1131px) {
        top: 32px;
        width: 30%;
    }
}

.winners {
    padding-top: 24px;
    padding-bottom: 24px;
    .header {
        display: flex;
        flex-direction: column;
        padding: 12px;
        padding-bottom: 0;
        
        .header-content {
            font-weight: bold;
            font-size: 1.2rem;
            color: #063B58;
        }

        @media (min-width: 769px) {
            flex-direction: row;
            align-items: center;
            padding: 12px 32px;
            padding-bottom: 0;
            justify-content: space-evenly;

            .header-content {
                margin-bottom: 0;
                font-size: 1.8rem;
                font-weight: bold;
            }
        }

        .prize-container {
            background-color: #063B58;
            padding: 12px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            align-self: center;

            @media (min-width: 769px) {
                padding: 12px 24px;
                align-self: initial;
            }

            & > * {
                margin-right: 8px;
            }

            .prize {
                margin-left: 16px;
            }
        }
        .header-mobile {
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 16px;
            @media (min-width: 769px) {
                display: none;
            }
        }
    }
}

.winners-table {
    border: 24px solid #063B58;
    border-radius: 40px;

    @media (min-width: 769px) {
        width: 85%;
        margin: 0 auto;
    }
}
.borderless {
   thead {
        background: #063B58;
        color: white;
    }

    tbody {
        background: white;
        color: #063B58;
    }


    
    th {
        border: none;
    }
 
    td {
        border: none;
    }

    tr {
        border: none;
     }
}

</style>
