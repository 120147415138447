<template>
  <div>
    <Header />

    <div class="redeemDesktop">
      <div class="steps" id="steps">
        <div class="container">
          <div>
            <h3>Review Items</h3>
          </div>

          <div>
            <h3>Confirm Redemption</h3>
          </div>
        </div>
      </div>

      <div class="containers">
        <div class="less">
          <ul>
            <li class="list active"></li>
            <li class="list "></li>
          </ul>
        </div>
      </div>

      <div class="exch" id="exch">
        <div class="container">
          <div class="row">
            <div class="col-4">
              <i
                ><img
                  style="height: 338px;width: 271px;"
                  src="@/assets/dragonnoprice.png"
              /></i>
            </div>

            <div class="redeem-prize col-8">
              <h3 class="redeem">
                Redeem <span class="bluer"> Dragon</span> Trophy
              </h3>

              <p class="redeem-p">
                You are about to redeem a premium trophy to add to your
                collection. This trophy can be converted to a cash value in my
                account.
              </p>

              <p class="redeem-p2">
                Keep collecting other available Trophies to cash out.
              </p>

              <p class="light">
                *You cannot undo this action once it is processed.
              </p>

              <p class="space">
                QTY:
                <span> xxxx Pachi Tickets</span>
              </p>

              <p class="spa">
                Total Cash Value:
                <span> $xx.xx</span>
              </p>

              <input class="btn add" type="submit" value="CONTINUE" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="redeemMobile">
      <div class="steps" id="steps">
        <div class="container">
          <div>
            <h3>Review Item</h3>
          </div>
        </div>
      </div>

      <div class="containers">
        <div class="less">
          <ul>
            <li class="list active"></li>
            <li class="list "></li>
          </ul>
        </div>
      </div>
      <div class="exch" id="exch">
        <div class="container">
          <h3 class="redeem">
            Redeem <span class="bluer"> Dragon</span> Trophy
          </h3>
          <div class="">
            <img class="mobileImage" src="@/assets/dragonnoprice.png" />
          </div>

          <div class="redeem-prize">
            <p class="redeem-p">
              You are about to redeem a premium trophy to add to your
              collection. This trophy can be converted to a cash value in my
              account.
            </p>

            <p class="redeem-p2">
              Keep collecting other available Trophies to cash out.
            </p>

            <p class="light">
              *You cannot undo this action once it is processed.
            </p>

            <div class="mobileBottom">
              <div>
                <p class="space">
                  QTY:
                  <span> xxxx Pachi Tickets</span>
                </p>
                <br />
              </div>
              <div>
                <p class="spa">
                  Total Cash Value:
                  <span> $xx.xx</span>
                </p>
                <br />
              </div>
              <div>
                <input class="btn add" type="submit" value="CONTINUE" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "RedeemDragon",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/prodredemption.scss";
</style>
