<template>
  <div>
    <Header />

    <div class="desktopPrize">
      <div class="exch" id="exch">
        <div class="container">
          <div class="row">
            <div class="col-5">
              <i><img class="imagesize" src="@/assets/prizekoi.png"/></i>
            </div>
            <div class="right-content col-7">
              <h3>Pachi Premium Prize</h3>
              <h3 class="cat">Koi - $10.00</h3>
              <p>
                Pachi Premium Trophies are exclusive Pachi Exchange prizes that
                have various cash value equivalent and can be collected in your
                account. You may choose to build up your trophy collection or
                you can choose to cash out their cash value to store them in
                your Pachi Wallet.
              </p>
              <form action="/product/confirmationtrophy">
                <div class="form-group">
                  <label class="qty" for="qty">QTY:</label>
                  <input type="text" id="qty" name="qty" /><br /><br />
                </div>
                <div class="form-group">
                  <label class="tickets" for="tickets">Tickets:</label>
                  <input type="text" id="tickets" name="tickets" />
                </div>
                <input class="add" type="submit" value="CONTINUE" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobilePrize">
      <div class="container">
        <h3 class="mobiletitle">Pachi Premium Prize - Koi</h3>
        <h3 class="cat">$10.00</h3>
        <div class="mobileImage">
          <i><img class="imagesize" src="@/assets/prizekoi.png"/></i>
        </div>
        <div class="mobileContent">
          <p class="mobilesmall">
            Pachi Premium Trophies are exclusive Pachi Exchange prizes that have
            various cash value equivalent and can be collected in your account.
            You may choose to build up your trophy collection or you can choose
            to cash out their cash value to store them in your Pachi Wallet.
          </p>
          <form action="/product/confirmationtrophy">
            <div class="form-group">
              <label class="qty" for="qtymobile">QTY:</label>
              <input type="text" id="qtymobile" name="qty" /><br /><br />
            </div>
            <div class="form-group">
              <label class="tickets" for="ticketsmobile">Tickets:</label>
              <input type="text" id="ticketsmobile" name="tickets" />
            </div>
            <div class="form-group">
              <input class="add" type="submit" value="CONTINUE" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "TrophyKoi",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/giftcards.scss";
</style>
