<template>
  <div>
    <Header />

    <div class="steps" id="steps">
      <div class="container">
        <div>
          <h3>Review Items</h3>
        </div>

        <div>
          <h3>Digital Delivery</h3>
        </div>

        <div>
          <h3>Exchange</h3>
        </div>
      </div>
    </div>

    <div class="containers">
      <div class="less">
        <ul>
          <li class="list "></li>
          <li class="list "></li>
          <li class="list active"></li>
        </ul>
      </div>
    </div>

    <div class="patchiex">
      <h2>CONGRATULATIONS</h2>
      <h2>Your Prize is being processed!</h2>
      <h2>Your prize will be emailed within 24hrs.</h2>
    </div>

    <div class="exch" id="exch">
      <div class="container">
        <div class="wrapper">
          <table>
            <div>
              <div class="exchange">
                <i
                  ><img
                    style="height: 147px;width: 123px;"
                    src="@/assets/phone.png"
                /></i>
                <h3 class="keep">
                  KEEP PLAYING PACHI+, <br />
                  KEEP COLLECTING TICKETS
                </h3>
                <p class="lorem">
                  Lorem ipsum dolor sit amet, consectetur adipiscing <br />
                  elit, sed do eiusmod tempor incididunt ut labore et <br />
                  dolore magna aliqua. Ut enim ad minim
                </p>

                <button type="button" id="browser" class="btn btn-primary">
                  PLAY GAME ON BROWSER
                </button>
              </div>
            </div>
          </table>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "ProductProcessing",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/productprocess.scss";
</style>
