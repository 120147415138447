<template>
    <div class="wrapper option-1 option-1-1" :style="styleVars">
        <ol class="c-stepper">
            <li class="c-stepper__item" :class="{ 'active': isActive(index) }" v-for="(item, index) in stepperItems" :key="index">
                <h3 class="c-stepper__title">{{ item }}</h3>
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'Stepper',
    props: {
        stepperItems: Array,
        step: Number
    },
    mounted() {
        const active = `.active::before {background-color:#297fca !important;}`
        let style = document.createElement('style');
        style.type = 'text/css';
        this.$el.appendChild(style)
        style.innerHTML = active;
    },
    data() {
        return {
            styleVars: {
                '--circle-size': 'clamp(1.5rem, 5vw, 3rem)',
                '--spacing': 'clamp(0.25rem, 2vw, 0.5rem)'
            },
        }
    },
    methods: {
        isActive(index) {
            return this.step === index + 1;
        }
    }
}
</script>

<style lang="scss" scoped>

.c-stepper {
  display: flex;
  padding: 32px 0;
  background: #034B73;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: lightgrey;
    background-color: white;
    margin: 0 auto 1rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - calc(var(--circle-size)));
      left: calc(50% + calc(var(--circle-size)/2));
      height: 2px;
      background-color: #297fca;
      order: -1;
    }
  }
}

.c-stepper__title {
  font-weight: bold;
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
  color: white;
}

</style>