<template>
  <div>
      <HeaderLogin />
    <div class="navMobile">
          <img
          src="@/assets/bglogin-mobile.png"
          class="navMobile"
          />
    </div>
    <div class="backgroundimg">
      <!-- <img src="@/assets/stock/bglogin-img.png" alt="Login Image" class="loginImg"> -->
      <div class="d-flex justify-content-center container">
        <div class="loginForm">
          <div>
            <h3>
              <img id="formlogo" class="navDesktop" src="@/assets/logo-white.png" />
              <img class="navMobile" src="@/assets/logo-blue.png"  style="margin-top:15px"/>
              
            </h3>
            <!-- <form action="/dist/main.html" method="GET"> -->
            <form>
              <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div> 
              <div style="border-color: white" class="input-group form-group">
                <input
                  type="text"
                  id="username"
                  class="form-control"
                  placeholder="Username"
                  v-model="username"
                />
              </div>
              <div class="input-group form-group" id="show_hide_password">
                <input
                  type="password"
                  name="password"
                  id="password"
                  class="form-control"
                  data-toggle="password"
                  placeholder="Password"
                  v-model="password"
                  v-on:keyup="validateLogin"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-eye"></i>
                  </span>
                </div>
              </div>
              <div class="row align-items-center">
                <a href="https://accounts.pachiplus.com/forgot_password.html" style="padding-left: 15px;color:#fff;" id="forgotpass"
                  ><u>I forgot my password</u></a
                >
              </div>
            </form>
          </div>
          <div class="">
            <div class="d-flex justify-content-center links">
              <!-- <input class="btn" id="loginbtn" type="submit" value="Log in" /> -->
              <a class="btn" id="loginbtn" @click="loginUser" role="button"
                >Log in</a
              >
            </div>
            <div class="d-flex justify-content-center">
              <a class="btn" id="signupbtn" href="/create-account" role="button"
                >Sign Up</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
        <Footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { router } from '../router';
import HeaderLogin from "../components/HeaderLogin";
import Footer from "../components/Footer";
export default {
  name: "Login",
  components: {
      HeaderLogin,
      Footer
  },
    data () {
        return {
            username: '',
            password: '',
            submitted: false,
        }
    },
  computed: {
      ...mapState('account', ['status']),
      ...mapState({
            alert: state => state.alert
        })
    },
    created () {
      this.logout();
    },
    methods: {
        ...mapActions('account', ['login', 'logout']),
        async loginUser() {
            this.submitted = true;
            const { username, password } = this;
            if (username && password) {
                const data = await this.login({ username, password });
                if (data === 'SUCCESS') {
                    router.push('/home')
                }
                                
            }
        },
         ...mapActions({
            clearAlert: 'alert/clearAlert' 
        }),
        validateLogin(e){
            this.clearAlert();
            if (e.keyCode === 13) {
                this.loginUser()
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/scss/login.scss';
</style>