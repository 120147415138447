<template>
  <div>
        <Header />
            <section>
                <div class="header">
                    <h2 class="header-text">
                        EDIT MY ACCOUNT
                    </h2>
                    <p class="header-content">
                        Ensure your details are correct and match the details on your Pachi+ account.
                    </p>
                </div>

                <section class="container-fluid details">
                    <div v-if="alert.message" :class="`alert ${alert.type}`">
                        {{ alert.message}}
                    </div>
                    <form class="form container">
                        <div>
                            <h2 class="header">
                                Personal Details
                            </h2>
                            <div class="form-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                :icon="['far', 'user-circle']"
                                class="content-icon"
                                />
                                <input
                                    type="text"
                                    class="form-control"
                                    id="firstName"
                                    placeholder="First Name / Last Name"
                                    v-model="edituser.fullName"
                                    required
                                />
                            </div>
                            <div class="form-group col-md-3  offset-md-2 no-icon">
                                <select
                                    class="form-control"
                                    name="gender"
                                    id="gender"
                                    required
                                    v-model="edituser.genderID"
                                >
                                    <option
                                    v-for="option in options.genderoption"
                                    :value="option.value"
                                    v-bind:key="option.value"
                                    >
                                    {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                icon="birthday-cake"
                                class="content-icon"
                                />
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="birthdate"
                                    placeholder="Birthday(YYYY-MM-DD)"
                                    data-provide="datepicker"
                                    v-model="edituser.dateOfBirth"
                                    required
                                    />
                            </div>
                            <div class="form-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                icon="mobile-alt"
                                class="content-icon mobile-icon"
                                />
                                    <input
                                    type="text"
                                    class="form-control"
                                    placeholder="phone number"
                                    id="number"
                                    v-model="edituser.phoneNumber"
                                    />
                            </div>
                            <div class="form-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                    :icon="['far', 'envelope']"
                                    class="content-icon"
                                />
                                <input
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    placeholder="Email"
                                    v-model="edituser.emailAddress"
                                    readonly
                                />
                            </div>
                            <div class="form-group col-md-3 offset-md-2">
                                <font-awesome-icon
                                icon="globe"
                                class="content-icon"
                                />
                                <select
                                    v-model="edituser.countryID"
                                    class="form-control"
                                    name="country"
                                    id="country"
                                    required
                                >
                                    <option value="" disabled selected hidden>Country</option>
                                    <option v-for="c in countryoption" :value="c.id" v-bind:key="c.id">
                                        {{ c.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <h2 class="header">
                                Shipping Details
                            </h2>
                            <div class="form-group col-md-6 offset-md-2 shipping-info">
                                <div class="input-group">
                                    <font-awesome-icon
                                        icon="user-circle"
                                        class="content-icon"
                                        />
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="shipFirstName"
                                        placeholder="First Name"
                                        required
                                        v-model="edituser.firstname"
                                    />
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="shipLastName"
                                        placeholder="Last Name"
                                        required
                                        v-model="edituser.lastname"
                                    />
                                </div>
                            </div>
                            <div class="form-group address-info col-md-9 offset-md-2">
                                <div class="input-group">
                                    <font-awesome-icon icon="map-marker-alt" class="content-icon address-icon" />
                                    <input
                                        type="text"
                                        class="form-control col-md-3"
                                        id="addrline1"
                                        placeholder="Address Line 1"
                                        v-model="edituser.shippingaddress1"
                                    />
                                    <input
                                        type="text"
                                        class="form-control col-md-3"
                                        id="addrline2"
                                        placeholder="Address Line 2"
                                        v-model="edituser.shippingaddress2"
                                    />
                                    <input
                                        type="text"
                                        class="form-control col-md-3"
                                        id="city"
                                        placeholder="City"
                                        v-model="edituser.city"
                                    />
                                    
                                </div>
                            </div>
                            <div class="form-group address-info second-column col-md-9 offset-md-2">
                                <div class="input-group">
                                    <select
                                        class="form-control col-md-3"
                                        name="Country"
                                        id="country2"
                                        v-model="edituser.shippingcountryid"
                                    >
                                        <option
                                            v-for="item in shipcountryoption"
                                            :value="item.id"
                                            v-bind:key="item.id"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <input
                                        type="text"
                                        name="PostalCode"
                                        placeholder="Postal Code"
                                        size="33"
                                        id="postalCode"
                                        class="form-control col-md-3"
                                        maxlength="32"
                                        v-model="edituser.postalcode"
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 class="header">
                                Replace Password
                            </h2>
                            <div class="form-group col-md-3 offset-md-2">
                                <font-awesome-icon icon="lock" class="content-icon" />
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Old Password"
                                    id="oldPassword"
                                    v-model="oldPass"
                                />
                            </div>
                            <div class="form-group col-md-3 no-icon offset-md-2">
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="New Password"
                                    id="newPassword"
                                    required
                                    v-model="newPass"
                                />
                            </div>
                            <div class="form-group col-md-3 no-icon offset-md-2">
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Confirm New Password"
                                    id="newPassword2"
                                    v-model="confirmPass"
                                />
                            </div>
                        </div>
                        <div class="form-group submit">
                            <input
                                type="submit"
                                class="btn"
                                value="Submit"
                                id="submitBtn"
                                @click.prevent="UpdateUser"
                            />
                        </div>
                    </form>
                </section>
            </section>
        <Footer />
    </div>
</template>

<script>
import { apiurl } from "../server.config";
const token = localStorage.getItem("token");
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import moment from "moment";
import Header from "../components/Header";
import Footer from "../components/Footer";
//import { router } from '../router';
export default {
  name: "EditAccount",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      selected: 2,
      edituser: {
        fullName: "",
        displayName: "",
        emailAddress: "",
        password: "",
        phoneNumber: "",
        genderID: 1,
        countryID: 1,
        dateOfBirth: "",
        ageconfirm: 0,
        permit: 1,
        shippingaddress1: "",
        shippingaddress2: "",
        city: "",
        shippingcountryid: 1,
        postalcode: 0,
        firstname: "",
        lastname: "",
        age_confirmation: 1,
        permitConfirmation: 1,
      },
      oldPass: "",
      newPass: "",
      confirmPass: "",
      submitted: false,
      countryoption: [],
      shipcountryoption: [],
      options: {
        genderoption: [
          {
            value: 1,
            label: "Male",
          },
          {
            value: 2,
            label: "Female",
          },
          {
            value: 3,
            label: "Disclose",
          },
        ],
      },
    };
  },
  
  computed: {
    ...mapState("account", ["status"]),
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
    }),
  },
  created() {
    this.getUser();
    this.getCountry();
  },
  methods: {
    ...mapActions("account", ["login", "update"]),
    ...mapMutations("alert", ['error', 'clearAlert']),
    getCountry() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      axios
        .get(apiurl + "exchange/displaycountry", { headers: headers })
        .then((response) => {
          this.countryoption = response.data;
          this.shipcountryoption = response.data;
        });
    },
    getUser() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const id = this.account.user.data.id;
      axios
        .get(apiurl + "exchange/myuserwithdetails/" + id, { headers: headers })
        .then((response) => {
          this.edituser = response.data;
          this.edituser.dateOfBirth = this.formatbday(
            response.data.dateOfBirth
          );
    });
    },
    async UpdateUser() {
        this.clearAlert();
        this.submitted = true;
        const { oldPass, newPass, confirmPass } = this
        const { id,
            fullName,
            dateOfBirth,
            countryID,
            phoneNumber,
            genderID,
            firstname,
            lastname,
            shippingaddress1,
            shippingaddress2,
            city,
            shippingcountryid,
            postalcode } = this.edituser

            if (!fullName || !dateOfBirth || !phoneNumber || !firstname || !lastname || !shippingaddress1 || !city || !postalcode) {
                this.error('Please fill up the required fields');
                return;
            }

            const user = {
                id,
                fullName,
                dateOfBirth,
                countryID,
                phoneNumber,
                genderID,
                firstname,
                lastname,
                shippingaddress1,
                shippingaddress2,
                city,
                shippingcountryid,
                postalcode,
                password: ''
            }

        if(!fullName || !dateOfBirth){
            this.error('Please fill up required fields');
            return;
        }
        if (oldPass) {
            try {
                const username = this.edituser.displayName
                const password = oldPass
                const data = await this.login({ username, password })
                if (!data) {
                    this.error('Old password is incorrect.')
                    return;
                } else {
                    if (!newPass) {
                        this.error('Please put a new password.')
                        return;
                    }
                    if (newPass !== confirmPass) {
                        this.error('New passwords do not match.')
                        return
                    }
                    user.password = newPass
                }
            } catch(e) {
                this.error('Old password is incorrect.')
            }
        }
        this.update(user);
    },
    formatbday(date) {
      return moment(String(date)).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0 12px;
    .header-text {
        line-height: 52px;
        margin: auto;
        margin-bottom: 0;
        font-size: 2rem;

        @media (min-width: 576px) {
            margin: 0;
            font-size: 3rem;
        }
    }

    .header-content {
        font-size: 1rem;

        @media (min-width: 576px) {
            font-size: 1.5rem;
        }
    }
}

.container-fluid {
    padding: 0;
}

.form {
    color: white;
    background: #034B73;
    padding: 12px;
    @media (min-width: 576px) {
        padding: 24px;
    }
    .header {
        margin-top: 24px;
        font-size: 1.5rem;
        @media(min-width: 576px) {
            font-size: 2rem;
        }
    }

    .content-icon {
        display: none;

        @media (min-width: 576px) {
            display: block;
            font-size: 2rem;
            margin-right: 12px;

            &.mobile-icon {
                margin-left: 5px;
                margin-right: 17px;
            }

            &.address-icon {
                margin-left: 4px;
                margin-right: 16px;
            }
        }
    }

    .form-group {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;

        &.no-icon {
            @media (min-width:576px) {
                padding-left: calc(2rem + 24px);
            }
        }

        .form-control {
            &:not(:last-child) {
                margin-bottom: 12px;

                @media (min-width: 576px) {
                    margin-right: 12px;
                }
            }
        }

        &.shipping-info, &.address-info {
            .form-control {
                width: 100%;
                @media (min-width: 576px) {
                    max-width: 200px;
                    margin-bottom: 12px;
                }
           }

        }
        &.second-column {
            .form-control:first-child {
                @media (min-width: 576px) {
                    margin-left: 44px;
                }
            }
        }

    }
}

.submit {
    justify-content: center;

    .btn {
        margin-top: 16px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        background-color: #063B58;
        border-radius: 11px;
        text-align: center;
        padding: 12px 24px;

        &:hover {
            color: #034B73;
            outline: none;
        }
    }
}

.mobile-only {
    display: block;
    @media (min-width: 576px) {
        display: none;
    }
}

</style>
