<template>
  <div>
    <Header />
    <Stepper :stepperItems="['Review Items', 'Confirm Redemption']" :step="1" />
    <section>
        <div class="container shop-exchange" v-if="hasItem">
            <div class="left-content">
                <img :src="getImgUrl(item.imagebig)" />
            </div>
            <div class="right-content">
                <div class="desktop-header">
                    <h3 class="header-text"> Redeem <span style="color:#063B58;">{{ item.name }}</span></h3>
                </div>
                <div class="mobile-header">
                    <h3 class="header-text">Redeem <span style="color:#063B58;">{{ item.name }}</span></h3>
                </div>
                <img class="mobile-img" :src="getImgUrl(item.imagebig)" />
                <p>
                    You are about to redeem a premium trophy to convert into cash value. 
                    After redemption, the cash value will be added to your wallet and can later be cashed out to you Paypal account and other payment gateways.
                </p>
                <p>
                    Keep collecting other available Trophies to cash out.
                </p>
                <p style="color: #94bee3;">
                    *You cannot undo this action once it is processed.
                </p>
                <form class="form">
                    <div class="form-group details">
                        <div>
                            <label class="form-label">QTY:</label>
                            <label class="form-label">1</label>
                        </div>
                        <div>
                            <label class="form-label" for="tickets">Total Cash Value:</label>
                            <label class="tickets" id="tickets" name="tickets">
                                {{ item.amount }}
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            class="btn"
                            @click.prevent="redeemItem"
                            type="submit"
                            value="CONTINUE"
                        />
                    </div>
                    
              </form>
            </div>
        </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { apiurl } from '../server.config'
import { mapState } from 'vuex';
import axios from 'axios';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from '../components/Stepper.vue';
import { router } from '../router';

const token = localStorage.getItem('token');
const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
}

export default {
  name: "ProductRedeem",
  components: {
    Header,
    Footer,
    Stepper
  },data () {
    return {
      item: {},
      hasItem: false,
      exchangeitem: [],
    }
  },  
  computed: {
      ...mapState({
            alert: state => state.alert,
            account: state => state.account,
        })
        
   },
     created () {
        this.getItemforChange();
    },
    methods: {
      getItemforChange(){
         //const apiurl = `http://localhost:8881/item`;

            axios
          .get(apiurl + "items/itemforchangedetails/" + this.$route.params.exchangeid,{headers})
          .then((response) => {
            this.item = {...response.data};
            this.hasItem = true;
          });
      },
        redeemItem(){
       const param = { id: this.$route.params.redeemid, userId: this.account.user.data.id};
            axios
          .post(apiurl + "items/redeemcollections",param,{headers})
          .then((response) => {
           //  alert(response.data.message);
              if(response.data.result == "SUCCESS"){
                      router.push('/redeem/confirmation');
              } else {
                  alert('Redeeming the item is not successful')
              }
          });
      },
      getImgUrl(pic) {
      return require('../assets/shop/'+pic)
    }
    
    }
};
</script>

<style lang="scss" scoped>
.shop-exchange {
        padding: 32px 0;
        display: flex;
        flex-direction: row;

        .left-content {
            display: none;

            @media (min-width: 576px) {
                display: block;
                margin-right: 40px;
                padding: 24px;
           }
        }

        .right-content {
            text-align: center;
            padding: 12px;

            &> * {
               margin: 18px 0; 
            }

            p {
                text-align: left;
            }

            @media(min-width: 576px) {
                margin-left: 40px;
                padding: 0;
                text-align: left
            }
        }

        .mobile-header {
            @media(min-width: 576px) {
                display: none;
            }
        }

        .mobile-img {
            @media(min-width: 576px) {
                display: none;
            }
        }

        .desktop-header {
            display: none;

            @media(min-width: 576px) {
                display: block;
                text-align: left;
            }
        }

        .header-text,.header-content {
            font-weight: 700;
            font-size: 1.8rem;
            text-transform: capitalize;
            @media(min-width: 576px) {
                font-size: 3rem;
            }
 
        }

        .header-text {
            color: #297FCA;
        }

        .header-content {
            color: #94bee3;
        }

    }

    .form {
        padding-top: 12px;
        justify-content: space-between;
        font-weight: 700;
        display: flex;

        .details {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 0;
            flex-direction: column;

            @media(min-width: 756px) {
                justify-content: flex-start;
            }
        }

        @media(max-width: 576px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .btn {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        background-color: #297fca;
        border-radius: 11px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 24px;

        &:hover {
            color: #ffffff;
            outline: none;
        }
    }
</style>