import { render, staticRenderFns } from "./MysteryBoxSmall.vue?vue&type=template&id=136610c7&scoped=true&"
import script from "./MysteryBoxSmall.vue?vue&type=script&lang=js&"
export * from "./MysteryBoxSmall.vue?vue&type=script&lang=js&"
import style0 from "./MysteryBoxSmall.vue?vue&type=style&index=0&id=136610c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136610c7",
  null
  
)

export default component.exports