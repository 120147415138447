<template>
  <div class="header">
    <div class="menu">
      <header id="main">
        <nav class="navbar navbar-expand-md">
            <div class="logo-container">
                <a href="/">
                    <img
                        id="headerlogo"
                        alt="Pachi Exchange logo"
                        src="../assets/logo-white.png"
                    />
                </a>
            </div>
          <ul class="navbar-nav ml-auto navDesktop">
            <li class="nav-item">
              <a class="btn" id="loginbtn" href="/login" role="button"
                >Log in</a
              >
            </li>
          </ul>
          <div class="navMobile">
            <Slide right noOverlay class="burgerMenu" width="200" :cross-icon="true">
              <a id="login" href="/login">
                <span>Login</span>
              </a>
            </Slide>
          </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  name: "HeaderIndex",
  components: { Slide },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// @import "@/styles/scss/signup.scss";
.header {
    background-color: #063B58;
    padding: 10px 0;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 576px) {
        height: 100px;
        padding: 10px 40px;
    }

    .menu {
        width: 100%;
    }

    .logo-container {
        max-width: 200px;
        @media (min-width: 576px) {
            max-width: 283px;
        }

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .navDesktop {
        display: none;

        @media (min-width: 576px) {
            display: block;
        }
    }
    .bm-burger-bars {
      background-color: #fff !important;
    }
    .navMobile {
        display: block;
        
        @media (min-width: 576px) {
            display: none;
        }
    }
    ::v-deep .bm-burger-button {
        top: 15px;
        height: 20px !important;
    }

    ::v-deep .bm-cross-button {
        right: 20px !important;
        top: 35px !important;
        width: 50px !important;
        height: 50px !important;

        .bm-cross {
            height: 15px !important;
            width: 5px !important;
        }
    }

    .btn {
        font-weight: 400;
        font-size: 16px;
        color: #063B58;
        background-color: #fff;
        border-radius: 11px;
        padding: 13px 25px;
    }
    .bm-burger-bars {
      background-color: #fff !important;
    }
.nav-link{
  color:#fff !important;
}
}

</style>
