<template>
  <div>
    <Header />
    <section class="container-fluid">
        <section class="logo-group">
          <div class="logo-container">
              <img class="logo" src="@/assets/mobileSweepLogo.png" />
          </div>
              <p>Win Daily, Weekly and Monthly prizes and rewards!</p>
        </section>

        <section>
        <img
          src="@/assets/entry.png"
          alt="Pachi Exchange Banner"
          class="banner desktop-banner"
        />
        <img
          src="@/assets/mobileSweepBanner.png"
          alt="Pachi Exchange Banner"
          class="banner mobile-banner"
        />
        </section>

        <section class="pachi-sweepstakes">
            <div class="header">
                <h2 class="header-text">How to Enter Sweepstakes</h2>
        </div>
            <div class="sweepstakes-container">
                <div class="left-content">
                    <div class="flex-item step-img-container">
                        <img src="@/assets/Group1.png"/>
                    </div>
                    <img class="flex-item" src="@/assets/Group 152.png" />
                    <div class="flex-item">
                        <h4 class="content-header">
                            Select Your Sweepstake Draw
                        </h4>
                        <p class="content-text">
                            Each player receives one free entry to a sweepstake draw daily. Select your Sweepstake Draw.
                        </p>
                    </div>
            </div>
                <div class="arrow-container">
                    <img class="arrow-desktop" src="@/assets/arrow-white.png" alt="Arrow" />
                    <img class="arrow-mobile" src="@/assets/arrow-down-white.png" alt="Arrow" />
                </div>
                <div class="center-content">
                    <div class="flex-item step-img-container">
                        <img src="@/assets/Group2.png" />
                    </div>
                    <img class="flex-item" src="@/assets/pic11.png" />
                    <div class="flex-item">
                        <h4 class="content-header">
                            Confirm Ticket Entry
                        </h4>
                        <p class="content-text">
                            Simply click ‘use my free entry button’ and you will be in the draw to win!
                        </p>
                    </div>
                </div>
                <div class="arrow-container">
                    <img class="arrow-desktop" src="@/assets/arrow-white.png" alt="Arrow" />
                    <img class="arrow-mobile" src="@/assets/arrow-down-white.png" alt="Arrow" />
                </div>
    
                <div class="right-content">
                    <div class="flex-item step-img-container">
                        <img src="@/assets/Group3.png" />
                    </div>
                    <img class="flex-item" src="@/assets/pic10.png" />
                    <div class="flex-item">
                        <h4 class="content-header">
                            Additional Entries
                        </h4>
                        <p class="content-text">
                            Increase your chances of winning by purchasing more entries by exchanging your Pachi Tickets.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="upcoming-draws container">
            <div class="header">
                <h2 class="header-text">
                    Upcoming Draws
                </h2>
            </div>
            <div class="draws">
                <div class="card">
                    <img class="img-top rounded-circle" src="@/assets/Component 4.png" />
                    <div class="card-body">
                        <h5 class="card-title">Daily Draw</h5>
                        <div class="time-display">
                            <div class="time-group">
                                <span>{{ timeLeft.hours || 0 }} </span>
                                <span>Hours</span>
                            </div>
                            <div class="time-group">
                                <span>{{ timeLeft.minutes || 0 }} </span>
                                <span>Mins</span>
                            </div>
                            <div class="time-group">
                                <span>{{ timeLeft.seconds || 0}} </span>
                                <span>Sec</span>
                            </div>
                       </div>
                        <span style="color: white;">Time Remaining</span>
                        <button class="btn" @click="buySweepstake('daily', timeLeft)">
                            Enter now
                        </button>
                    </div>
                </div>
                <div class="card">
                    <img class="img-top rounded-circle" src="@/assets/weekly.png" />
                    <div class="card-body">
                        <h5 class="card-title">Weekly Draw</h5>
                        <div class="time-display">
                            <div class="time-group">
                                <span>{{ weeklyTimeLeft.days || 0 }} </span>
                                <span>Days</span>
                            </div>
                            <div class="time-group">
                                <span>{{ weeklyTimeLeft.hours || 0 }} </span>
                                <span>Hours</span>
                            </div>
                            <div class="time-group">
                                <span>{{ weeklyTimeLeft.minutes || 0 }} </span>
                                <span>Mins</span>
                            </div>
                            <div class="time-group">
                                <span>{{ weeklyTimeLeft.seconds || 0}} </span>
                                <span>Sec</span>
                            </div>
                        </div>
                        <span style="color: white;">Time Remaining</span>
                        <button class="btn" @click="buySweepstake('weekly', weeklyTimeLeft)">
                            Enter now
                        </button>
                    </div>
                </div>
                <div class="card">
                    <img class="img-top rounded-circle" src="@/assets/monthly.png" />
                    <div class="card-body">
                        <h5 class="card-title">Monthly Draw</h5>
                        <div class="time-display">
                            <div class="time-group">
                                <span>{{ monthlyTimeLeft.days || 0 }} </span>
                                <span>Days</span>
                            </div>
                            <div class="time-group">
                                <span>{{ monthlyTimeLeft.hours || 0 }} </span>
                                <span>Hours</span>
                            </div>
                            <div class="time-group">
                                <span>{{ monthlyTimeLeft.minutes || 0 }} </span>
                                <span>Mins</span>
                            </div>
                            <div class="time-group">
                                <span>{{ monthlyTimeLeft.seconds || 0}} </span>
                                <span>Sec</span>
                            </div>
                        </div>
                        <span style="color: white;">Time Remaining</span>
                        <button class="btn" @click="buySweepstake('monthly', monthlyTimeLeft)">
                            Enter now
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <section class="results-container">
            <div class="header">
                <h2 class="header-text">
                    Is It Your Lucky Day?
                </h2>
                <p class="header-content">
                    Check the daily, weekly and monthly results here to see if you're one of the lucky winners.
                </p>
            </div>

            <div class="results">
                <button class="btn-view" @click="showResultsPage('daily')">
                    view daily result
                </button>
                <button class="btn-view" @click="showResultsPage('weekly')">
                    view weekly result
                </button>
                <button class="btn-view" @click="showResultsPage('monthly')">
                    view monthly result
                </button>
            </div>

        </section>
    </section>
    <Footer />
  </div>
</template>

<script>
import { apiurl } from "../server.config";
const token = localStorage.getItem("token");
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
//import { router } from '../router';
export default {
  name: "Sweepstakes",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      lottery: [],
      timeLeft: {},
      weeklyTimeLeft: {},
      monthlyTimeLeft: {},
    };
  },
  created() {
    this.getLottery();
  },
  methods: {
    getLottery() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      axios
        .get(apiurl + "lottery/lotteries", { headers: headers })
        .then((response) => {
          this.lottery = response.data;

          const [daily, weekly, monthly] = this.lottery;

          const dailySec = this.getSecond(daily.drawdatetime);
          const weeklySec = this.getSecond(weekly.drawdatetime);
          const monthlySec = this.getSecond(monthly.drawdatetime);

          this.setTime(dailySec, 'daily');
          this.setTime(weeklySec, 'weekly');
          this.setTime(monthlySec, 'monthly');
        });
    },
    buySweepstake(type, time) {
        const { days, hours, minutes, seconds } = time
        const formattedTime = `${days*24 + hours}:${minutes}:${seconds}`
      if (this.getSecond(formattedTime) === 0) {
          alert(`${type.charAt(0).toUpperCase()}${type.slice(1)} sweepstakes has ended!`)
          return true;
      }

      this.$router.push({path: `sweepstakes/buy/${type}`})
    },
    showResultsPage(item) {
      this.$router.push({ path: `sweepstakes/result/${item}` });
    },
    setTime(seconds, timeInterval) {
        const now = Date.now();
        const end = now + seconds * 1000;

        const intervalTimer = setInterval(() => {
            const secondsLeft = Math.round((end - Date.now()) / 1000);

            if (secondsLeft === 0) {
            this.endTime = 0;
            }

            if (secondsLeft < 0) {
                clearInterval(intervalTimer);
                return;
            }

            switch(timeInterval) {
                case 'daily':
                    this.timeLeft = this.displayTimeLeft(secondsLeft);
                    break;
                case 'weekly':
                    this.weeklyTimeLeft = this.displayTimeLeft(secondsLeft);
                    break;
               case 'monthly':
                    this.monthlyTimeLeft = this.displayTimeLeft(secondsLeft);
                    break;
            }
        }, 1000);
    },
    displayTimeLeft(secondsLeft) {
        const days = Math.floor(secondsLeft / 86400);
        const hours = Math.floor(secondsLeft / 3600) % 24;
        const minutes = Math.floor((secondsLeft % 3600) / 60);
        const seconds = secondsLeft % 60;
      return {
          days,
          hours,
          minutes,
          seconds
      }
    //   return `${this.zeroPadded(hour)}:${this.zeroPadded(
    //     minutes
    //   )}:${this.zeroPadded(seconds)}`;
    },
    zeroPadded(num) {
      // 4 --> 04
      return num < 10 ? `0${num}` : num;
    },
    getSecond(param) {
      let seconds = 0;
      const [hr, min, sec] = param.split(":");
      seconds = hr * 3600 + min * 60 + sec * 1;
      return parseInt(seconds);
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/sweepstakeentry.scss";
.container-fluid {
    padding: 0;

    .banner {
        width: 100%;
    }

    .desktop-banner {
        @media (max-width: 576px) {
            display: none;
        }
    }

    .mobile-banner {
        @media (min-width: 576px) {
            display: none;
        }

    }
}

.logo-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 16px;
    max-width: 75%;

    .logo-container {
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
    
    p {
        margin-top: 16px;
        font-size: 24px;
        font-weight: bold;
    }
    @media (min-width: 576px) {
        display: none;
    }
}

.header {
    .header-text {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 52px;
        margin: auto;


        @media (min-width: 576px) {
            font-size: 3rem;
        }
    }
}

.pachi-sweepstakes {
    padding: 32px 24px;
    background-color: #063B58; 
    color: white;
    
    .sweepstakes-container {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (min-width: 769px) {
            flex-direction: row;
            height: 500px;
        }

        .left-content, .center-content, .right-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .flex-item {
                padding-top: 12px;
                padding-bottom: 12px;

                &:first-child {
                    padding-top: 0;
                }

                .content-header {
                    font-weight: 700;
                }

                .content-text {
                    font-weight: 400;
                    font-size: 18px;
                }
            }

            .step-img-container {
                max-width: 90px;
            }
            
            img {
                max-width: 150px;
                width: 100%;
                object-fit: contain;
            }
            
        }

        .right-content {
            img {
                max-width: 144px;
                width: 100%;
                object-fit: contain;
            }
        }

        .arrow-container {
            .arrow-desktop {
                align-self: center;
                width: 100%;
                object-fit: contain;
                min-width: 100px;
                max-width: 220px;
                display: none;
                @media (min-width: 769px) {
                    display: block;
                }
            }
            .arrow-mobile {
                display: block;
                width: 100%;
                object-fit: contain;
                max-width: 80px;
                margin-bottom: 32px;
                @media (min-width: 769px) {
                    display: none;
                }
            }
    
        }
    }
}

.upcoming-draws {
    padding: 32px 24px;

    .draws {
        display: flex;
        flex-direction: column;
        padding: 32px 12px;
        justify-content: center;
        align-items: center;

        .card {
            flex: 1;
            margin: 12px;
            background: #063B58;
            border-color: #297fca;
            border-radius: 40px;
            align-items: center;
            min-width: 270px;
            max-width: 300px;

            .card-title {
                font-weight: 700;
                color: #fff;
                font-size: 2rem;
            }

            img {
                max-width: 230px;
                margin: 12px auto;
            }
            
            .card-body {
                & > * {
                    margin: 12px auto;
                }

                input {
                    color: #297fca;
                    background-color: #fff;
                    border-radius: 5px;
                }
            }
        }

        @media (min-width: 576px) {
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;

            
        }
    }


}
.results-container {
    padding: 32px 24px;
    background-color: #063B58;
    color: #ffff;
}

.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &> * {
            margin: 12px;
        }

    button {
        width: 60%;
    }

    @media (min-width: 576px) {
        flex-direction: row;

        button {
            width: auto;
        }
    }
}

.btn {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    background-color: #06DFD3;
    border-radius: 11px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 24px;
}

.btn-view {
    font-weight: 400;
    font-size: 16px;
    color: #063B58;
    background-color: #ffffff;
    border-radius: 11px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 24px;
    border-color: #fff;
}


.time-display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .time-group {
        color:#063B58;
        background: white;
        min-width: 65px;
        min-height:50px;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
    }
}
</style>
