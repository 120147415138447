import { apiurl } from '../server.config'
export const userService = {
    login,
    logout,
    authenticate,
    register,
    update,
    handleAxiosResponse

};

const server = apiurl;
const serverurl = server + `exchange/`;


function authenticate(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "username": username, "password": password })
    };

    return fetch(server + `authenticate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data) {
                localStorage.setItem('token', data.token);
            }
            return data.token;


        }).catch((err) => console.error(err));
}

function auth_header() {
    var token = localStorage.getItem('token');

    return { 'Content-Type': 'application/json', 'Authorization': "Bearer " + token }
}

function login(username, password, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },//auth_header(),
        body: JSON.stringify({ "emailAddress": username, "password": password })
    };

    return fetch(serverurl + `exchangelogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // // login successful if there's a jwt token in the response
            if (user.result == "SUCCESS") {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                return user;
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}
function register(user) {

    const requestOptions = {
        method: 'POST',
        headers: auth_header(),
        body: JSON.stringify(user)
    };


    return fetch(serverurl + `insertexchangeuser`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch((err) => console.error(err));
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: auth_header(),
        body: JSON.stringify(user)
    };

    return fetch(serverurl + "updateuserwithdetails", requestOptions).then(handleResponse).then(result => {
        return result;
    });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleAxiosResponse(response) {
    // return response.data.then(xdata => {
    //     const data = JSON.parse(xdata);
    // if (!response.ok) {
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
    }

    const error = response.statusText;
    return Promise.reject(error);
    // }

    //     return data;
    // });
}
export default userService