<template>
  <div>
    <Header />
    <Stepper :stepperItems="['Confirm Amount', 'Payment Option', 'Confirm Cashout']" :step="3" />
    <section>
        <div class="container shop-exchange">
            <div class="left-content">
                <div class="image-wrapper">
                    <img src="@/assets/cashout-complete.png" />
                </div>
            </div>
            <div class="right-content">
                <div class="header">
                    <h3 class="header-text">Cashout Request Received</h3>
                </div>
                <p style="font-size: 1.2rem;">
                    Your request is in progress. Please allow 5-10 days of processing. You will receive a notification email once the transaction is complete.
                </p>
                <a role="button" class="btn" href="/my-account">
                    back to my account
                </a>
           </div>
        </div>
    </section>
   <Footer />
  </div>
</template>

<script>

import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from '../components/Stepper.vue'
export default {
  name: "CashoutComplete",
  components: {
    Header,
    Footer,
    Stepper
  },
  
};
</script>

<style lang="scss" scoped>
.shop-exchange {
        padding: 32px 0;
        display: flex;
        flex-direction: row;

        .left-content {
            display: none;

            @media (min-width: 576px) {
                display: block;
                margin-right: 40px;
                padding: 24px;

                .image-wrapper {
                    width: 230px;
                    height: 230px;
                    background-color: #063B58;
                    border-radius: 50%;
                    position: relative;

                    img {
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }

        .right-content {
            text-align: center;
            padding: 12px;

            &> * {
               margin: 18px 0; 
            }

            p {
                text-align: center;

                @media (min-width: 576px) {
                    text-align: left;
                }
            }

            @media(min-width: 576px) {
                margin-left: 40px;
                padding: 0;
                text-align: left
            }
        }

        .header-text {
            font-weight: 700;
            font-size: 1.8rem;
            text-transform: capitalize;
            color: #063B58;
            @media(min-width: 576px) {
                font-size: 3rem;
            }
 
        }
    }

    .btn {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        background-color: #063B58;
        border-radius: 11px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 24px;

        &:hover {
            color: #eee;
            outline: none;
        }
    }

    ::v-deep .c-stepper__item {
        &:last-child {
            h3 {
                margin-top: 2px;
            }
        }
    }
</style>
