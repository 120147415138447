<template>
  <div>
    <Header />

    <div class="steps" id="steps">
      <div class="container">
        <div>
          <h3>Review Items</h3>
        </div>

        <div>
          <h3>Digital Delivery</h3>
        </div>

        <div>
          <h3>Exchange</h3>
        </div>
      </div>
    </div>
    <div class="containers">
      <div class="less">
        <ul>
          <li class="list "></li>
          <li class="list active"></li>
          <li class="list"></li>
        </ul>
      </div>
    </div>

    <div class="d-flex justify-content-center container content">
      <div class="loginForm text-center">
        <div class="">
          <h3>
            Verify Email
          </h3>
          <p>
            Provide your preferred email to receive your Google Play Gift Card.
            <br />
            Please check your Spam or Promotions folder if you fail to receive
            it in your inbox.
          </p>
          <form action="/home" method="GET">
            <div>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
              />
            </div>
          </form>
        </div>
        <div class="">
          <div class="d-flex justify-content-center links">
            <!-- <input class="btn" id="loginbtn" type="submit" value="Log in" /> -->
            <input
              class="btn"
              id="loginbtn"
              href="/"
              role="button"
              value="CONFIRM"
              type="submit"
            />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "ProductVerifyEmail",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/productverify.scss";
</style>
