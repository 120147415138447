<template>
  <div>
    <Header />

    <div class="steps" id="steps">
      <div class="container">
        <div>
          <h3>Review Items</h3>
        </div>

        <div>
          <h3>Digital Delivery</h3>
        </div>

        <div>
          <h3>Exchange</h3>
        </div>
      </div>
    </div>

    <div class="containers">
      <div class="less">
        <ul>
          <li class="list "></li>
          <li class="list "></li>
          <li class="list active"></li>
        </ul>
      </div>
    </div>

    <div class="patchiex">
      <h2>CONGRATULATIONS</h2>
      <h2>Your Gift Card delivery is being processed!</h2>
      <h2>Your prize will be emailed within 5-10 working days.</h2>
      <br />
    </div>

    <div class="exch" id="exch">
      <div class="container">
        <div class="wrapper">
          <div class="row">
            <div class="exchange col-3">
              <i
                ><img
                  style="height: 147px;width: 123px;"
                  src="@/assets/phone.png"
              /></i>
            </div>
            <div class="col-5">
              <h3 class="keep">
                KEEP PLAYING PACHI+, KEEP COLLECTING TICKETS
              </h3>
            </div>
            <div class="col-4">
              <a href="https://play.pachiplus.com/" target="_blank"><button id="browser" class="btn">
                PLAY GAME ON BROWSER
              </button></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "ProductConfirmation",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/productconfirmation.scss";
</style>
