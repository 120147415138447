<template>
  <div>
    <HeaderLogin />

    <div class="verifyDesktop">
      <div class="prof">
        <h2>You’re Almost Done!</h2>
        <p class="thanks">
          Thank you for signing up to Pachi+ <br /><br />
          You’re just more step away from enjoying the most rewarding online
          <br />
          Pachinko game ever. <br />
          <br />
          Please verify your email to confirm your account.
        </p>
        <br />

        <p class="please">
          *Please check your SPAM or PROMOTIONS folder if our email does not
          show up in your inbox.
        </p>
      </div>
    </div>

    <div class="verifyMobile">
      <div class="prof">
        <h2>You’re Almost Done!</h2>
        <p class="thanks">
          Thank you for signing up to Pachi+
        </p>
        <p class="thanks">
          You’re just more step away from enjoying the most rewarding online
          Pachinko game ever.
        </p>
        <p class="thanks">Please verify your email to confirm your account.</p>

        <p class="please">
          *Please check your SPAM or PROMOTIONS folder if our email does not
          show up in your inbox.
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeaderLogin from "../components/HeaderLogin";
import Footer from "../components/Footer";
export default {
  name: "VerifyEmail",
  components: {
    HeaderLogin,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/thankyou.scss";
</style>
