<template>
  <div>
    <Header />

    <div class="desktopPrize">
      <div class="exch" id="exch">
        <div class="container">
          <div class="row">
            <div class="col-5">
              <i><img class="imagesize" src="@/assets/boxmedium.png"/></i>
            </div>
            <div class="right-content col-7">
              <h3>Pachi Mystery Box</h3>
              <h3 class="cat">Medium Size</h3>
              <p class="small">Redeem Your Medium Mystery Box.</p>
              <p class="small">
                Receive a few items from Pachi Exchange.
              </p>
              <p class="small">
                The items can range from toys, essentials, beauty products and
                fun tech gadgets. Our team will personally select this surprise
                for you.
              </p>
              <p class="small">
                The mystery box will be delivered to your confirmed home
                delivery address with your Pachi+ account. Please validate your
                address in the next step.
              </p>
              <p class="small">
                Delivery will vary depending on your location and couriers
                delivery times.
              </p>
              <p class="smal">
                *Only available in the Philippines
              </p>
              <form action="/product/confirmationmysterybox">
                <div class="form-group">
                  <label class="qty" for="qty">QTY:</label>
                  <input type="text" id="qty" name="qty" /><br /><br />
                </div>
                <div class="form-group">
                  <label class="tickets" for="tickets">Tickets:</label>
                  <input type="text" id="tickets" name="tickets" />
                </div>
                <input class="add" type="submit" value="CONTINUE" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobilePrize">
      <div class="container">
        <h3 class="mobiletitle">Pachi Mystery Box</h3>
        <h3 class="cat">Medium Size</h3>
        <div class="mobileImage">
          <i><img class="imagesize" src="@/assets/boxmedium.png"/></i>
        </div>
        <div class="mobileContent">
          <p class="mobilesmall">
            Redeem Your Medium Mystery Box. The items can range from toys,
            essentials, beauty products and fun tech gadgets. Our team will
            personally select this surprise for you. The mystery box will be
            delivered to your confirmed home delivery address with your Pachi+
            account. Please validate your address in the next step. Delivery
            will vary depending on your location and couriers delivery times.
          </p>
          <p class="mobilesmal">
            *Only available in the Philippines
          </p>
          <form action="/product/confirmationmysterybox">
            <div class="form-group">
              <label class="qty" for="qtymobile">QTY:</label>
              <input type="text" id="qtymobile" name="qty" /><br /><br />
            </div>
            <div class="form-group">
              <label class="tickets" for="ticketsmobile">Tickets:</label>
              <input type="text" id="ticketsmobile" name="tickets" />
            </div>
            <div class="form-group">
              <input class="add" type="submit" value="CONTINUE" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "MysteryBoxMedium",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/giftcards.scss";
</style>
