<template>
  <div>
    <HeaderLogin />

    <div class="welcomeDesktop">
      <div class="prof">
        <h2>Hooray! Welcome, player!</h2>
        <br />
        <p class="thanks">You are now an official Pachi+ player!</p>
        <br />

        <p class="please">
          Start playing
          <a href="https://pachiplus.com/" class="pachiplus" target="_blank"
            >Pachi+</a
          >
          and start earning Pachi Tickets to exchange and
          <br />
          collect Premium Prizes here on Pachi Exchange. Enjoy!
        </p>

        <a class="btnwel" id="loginbtn" href="/login" role="button">LOG IN TO MY ACCOUNT</a>
      </div>
    </div>

    <div class="welcomeMobile">
      <div class="prof">
        <h2>Hooray! Welcome, player!</h2>
        <p class="thanks">You are now an official Pachi+ player!</p>

        <p class="please">
          Start playing
          <a href="https://pachiplus.com/" class="pachiplus" target="_blank"
            >Pachi+</a
          >
          and start earning Pachi Tickets to exchange and collect Premium Prizes
          here on Pachi Exchange. Enjoy!
        </p>

        <a href="/login"><button class="btnwel" id="loginbtn">LOG IN</button></a>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeaderLogin from "../components/HeaderLogin";
import Footer from "../components/Footer";
export default {
  name: "Welcome",
  components: {
    HeaderLogin,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/welcome.scss";
</style>
