<template>
  <div>
    <Header />

    <div class="steps" id="steps">
      <div class="container">
        <div>
          <h3>Review Items</h3>
        </div>

        <div>
          <h3>Digital Delivery</h3>
        </div>

        <div>
          <h3>Exchange</h3>
        </div>
      </div>
    </div>

    <div class="containers">
      <div class="less">
        <ul>
          <li class="list active "></li>
          <li class="list"></li>
          <li class="list"></li>
        </ul>
      </div>
    </div>

    <div class="exch" id="exch">
      <div class="container">
        <div class="row">
          <div class="halfleft col">
            <i
              ><img
                class="productIMG"
                style="height: 288px;width: 232px;"
                src="@/assets/reviewcat.png"
            /></i>
          </div>

          <div class="halfright col">
            <div style="padding-top: 50px;">
              <h3>Pachi Premium Prize</h3>
              <h3 class="luckcat">Lucky Cat</h3>
              <p>
                Lorem ipsum dolor sit amet,<br />
                consectetur adipiscing elit, sed do <br />eiusmod tempor
                incididunt ut labore<br />
                et dolore magna aliqua. Ut enim
              </p>

              <p class="qty">
                QTY:
                <span style="padding: 10px;"> xxxx</span>
              </p>
              <br />
              <p class="tickets">
                Tickets Cost:
                <span style="padding: 10px;"> xxxx</span>
              </p>
            </div>
            <button class="add">Continue</button>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "ProductReview",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/productreview.scss";
</style>
