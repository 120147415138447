<template>
  <div>
    <Header />

    <div class="desktopPrize">
      <div class="exch" id="exch">
        <div class="container">
          <div class="row">
            <div class="col-5">
              <i><img class="imagesize" src="@/assets/apple50.png"/></i>
            </div>
            <div class="right-content col-7">
              <h3>Apple Store Gift Card</h3>
              <h3 class="cat">$50.00</h3>
              <p class="small">
                Shop or use for credit in Apple Retail Store or Apple Online
                Store with this gift card.
              </p>
              <p class="small">
                This Gift Card will be delivered to your email with the
                nominated amount exchanged with your tickets under your name.
              </p>
              <p class="small">
                Please allow 3-5 working days to process your Gift Card. For
                security purposes, we will be required to do a level 1
                verification. To confirm your order, Please check your nominated
                email for Pachi.
              </p>
              <p class="smal">
                *This gift card can only be used in Apple Retail Store or Apple
                Online Store. For security purposes and to confirm your order
                you will be required to verify your account.
              </p>
              <form action="/product/amazon/verify-email">
                <div class="form-group">
                  <label class="qty" for="qty">QTY:</label>
                  <input type="text" id="qty" name="qty" /><br /><br />
                </div>
                <div class="form-group">
                  <label class="tickets" for="tickets">Tickets:</label>
                  <input type="text" id="tickets" name="tickets" />
                </div>
                <input class="add" type="submit" value="CONTINUE" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobilePrize">
      <div class="container">
        <h3>Apple Store Gift Card</h3>
        <h3 class="cat">$50.00</h3>
        <div class="mobileImage">
          <i><img class="imagesize" src="@/assets/apple50.png"/></i>
        </div>
        <div class="mobileContent">
          <p class="mobilesmall">
            Shop or use for credit in Apple Retail Store or Apple Online Store
            with this gift card.
            This Gift Card will be delivered to your email with the nominated
            amount exchanged with your tickets under your name.
            Please allow 3-5 working days to process your Gift Card. For
            security purposes, we will be required to do a level 1 verification.
            To confirm your order, Please check your nominated email for Pachi.
          </p>
          <p class="mobilesmal">
            *This gift card can only be used in Apple Retail Store or Apple
            Online Store. For security purposes and to confirm your order you
            will be required to verify your account.
          </p>
          <form action="/product/amazon/verify-email">
            <div class="form-group">
              <label class="qty" for="qtymobile">QTY:</label>
              <input type="text" id="qtymobile" name="qty" /><br /><br />
            </div>
            <div class="form-group">
              <label class="tickets" for="ticketsmobile">Tickets:</label>
              <input type="text" id="ticketsmobile" name="tickets" />
            </div>
            <div class="form-group">
              <input class="add" type="submit" value="CONTINUE" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "AppleStore50",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/giftcards.scss";
</style>
