<template>
    <div>
        <Header />
        <Stepper :step="2" :stepperItems="['Confirm Amount', 'Payment Option', 'Confirm Cashout']"/>
        <section class="container-fluid">
            <div class="page-header">
                <h1 class="header-text">{{ Number($props.paymentType) === 4 ? 'enter your bank details' : 'enter your paypal details'}}</h1>
                <p class="header-content">Ensure your details are correct</p>
            </div>
            <section class="payment-details-form" v-if="showForm">
                <form class="container">
                    <div class="bank-details offset-md-1" v-if="Number($props.paymentType) === 4">
                        <div class="form-group">
                            <div class="row">
                                <div class="form-group col-md-7">
                                    <label for="swiftCode">SWIFT/BIC Code</label>
                                    <input type="text" class="form-control" id="swiftCode" placeholder="bank code" v-model="bankDetails.bankCode"/>
                                    <small id="swiftCodeHelp" class="form-text-muted">Find a Bank Code</small>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="country">Country</label>
                                    <select
                                        class="form-control"
                                        name="country"
                                        id="country"
                                        required
                                        v-model="selectedCountry"
                                    >
                                        <option value="" disabled selected hidden>Country</option>
                                        <option v-for="c in countryList" :value="c.id" v-bind:key="c.id">
                                            {{ c.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                       </div>
                        <div class="form-group col-md-7">
                            <label for="accountNumber">Account Number or IBAN</label>
                            <input type="text" class="form-control" id="accountNumber" placeholder="bank account number" v-model="bankDetails.accountNumber"/>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="form-group col-md-7">
                                    <label>Account Name</label>
                                    <div class="row">
                                        <input type="text" class="form-control col-md-5 first-name" placeholder="first name" v-model="bankDetails.firstName"/>
                                        <input type="text" class="form-control col-md-5 offset-md-2 last-name" placeholder="last name" v-model="bankDetails.lastName"/>
                                    </div>
                                </div>
                                <div class="form-group button-group col-md-4">
                                    <button class="btn" @click.prevent="$router.push({name: 'Payment Option', params: {cashoutAmount: $props.cashoutAmount}})">Back</button>
                                    <button class="btn" type="submit" @click.prevent="processCashout">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paypal-details" v-else>
                        <div class="form-group paypal-account">
                            <label for="paypalAccount">Your Paypal Account</label>
                            <input type="text" class="form-control" id="paypalAccount" placeholder="Name, email, mobile number" v-model="paypalAccount"/>
                        </div>
                        <div class="form-group button-group paypal-account">
                            <button class="btn" @click.prevent="$router.push({name: 'Payment Option', params: {cashoutAmount: $props.cashoutAmount}})">Back</button>
                            <button class="btn" type="submit" @click.prevent="processCashout">Next</button>
                        </div>
                    </div>
                    
                </form>
            </section>
        </section>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from '../components/Stepper.vue'

import { apiurl } from "../server.config";
const token = localStorage.getItem("token");
const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
};
 

export default {
    name: 'CashoutPaymentDetails',
    props: ['paymentType', 'cashoutAmount'],
    components: {
        Header,
        Footer,
        Stepper
    },
    data() {
        return {
            bankDetails: {
                bankCode: '',
                countryId: '',
                accountNumber: '',
                lastName: '',
                firstName: ''
            },
            paypalAccount: '',
            countryList: [],
            selectedCountry: '',
            showForm: false
        }
    },
    computed: {
        ...mapState({
            account: (state) => state.account
        })
    },
    methods: {
        async processCashout() {
            const { id: userId } = this.account.user.data;
            const bankDetailParams = { ...this.bankDetails, userId }
            const paypalAccountParams = { userId, accountNumber: this.paypalAccount }
            const cashoutParams = {
                userID: userId,
                amount: this.cashoutAmount,
                paymentTypeID: this.paymentType
            }
            try {
                if (Number(this.paymentType) === 4) {
                    await axios.post(`${apiurl}payment/insertbankdetails`, bankDetailParams, { headers })
                } else {
                    await axios.post(`${apiurl}payment/insertpaypaldetails`, paypalAccountParams, { headers })
                }
                    await axios.post(`${apiurl}payment/insertcashout`, cashoutParams, { headers })
                    this.$router.push("/cashout/complete");
            } catch(e) {
                console.error(e)
                alert('Error in processing your cashout')
            }
        },
        async getBankDetails() {
            const { id: userId } = this.account.user.data;
            try {
                const { data } = await axios.get(`${apiurl}payment/mybankdetails/${userId}`, { headers })
                this.bankDetails = {...data}
                this.selectedCountry = data.countryId
                this.showForm = true
            } catch(e) {
                console.error(e)
            }
        },
        async getPaypalDetails() {
            const { id: userId } = this.account.user.data;
            try {
                const { data } = await axios.get(`${apiurl}payment/mypaypaldetails/${userId}`, { headers })
                this.paypalAccount = data.accountNumber
                this.showForm = true
            } catch(e) {
                console.error(e)
            }
        },
        async getCountry() {
            try {
                const { data } = await axios.get(apiurl + "exchange/displaycountry", { headers })
                this.countryList = [...data]
            } catch(e) {
                console.error(e)
            }
        }
    },
    async mounted() {
        this.getCountry();
        if (Number(this.paymentType) === 4) {
            this.getBankDetails();
        } else {
            this.getPaypalDetails();
        }
    }
}
</script>

<style lang="scss" scoped>
.container-fluid {
    padding: 0;
}
.page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 32px auto;
    padding: 0 12px;
    .header-text {
        font-weight: bold;
        text-transform: uppercase;
        line-height: 52px;
        margin: auto;
        margin-bottom: 0;
        font-size: 2rem;

        @media (min-width: 576px) {
            margin: 0;
            font-size: 3rem;
        }
    }

    .header-content {
        font-size: 1rem;

        @media (min-width: 576px) {
            font-size: 1.5rem;
        }
    }
}

.payment-details-form {
    margin-bottom: 32px;
    background: #034B73;
    color: white;
    padding: 48px 0;

    input, select {
        color: #297fca;

    }
}

.form-group {
    text-align: left;
    justify-content: flex-start;
}

.button-group {
    padding: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .btn {
        &:last-child {
            margin-left: 1.5rem;
        }
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        background: #063B58;
        border-radius: 11px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 24px;

        &:hover {
            color: #ffffff;
            outline: none;
        }
    }
}

.row {
    text-align: left;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
}

.last-name {
    margin-top: .5rem;

    @media (min-width: 576px) {
        margin-top: 0;
    }
}

.first-name {
    margin-bottom: .5rem;

    @media (min-width: 576px) {
        margin-bottom: 0;
    }
}

.paypal-account {
    justify-content: center;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (min-width: 576px) {
        flex: 0 0 58%;
        max-width: 58%;
    }
}

</style>