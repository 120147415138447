<template>
  <div class="header">
    <div class="menu">
      <header id="main">
        <nav class="navbar navbar-expand-md">
          <a href="/"
            ><img
              id="headerlogo"
              alt="Pachi Exchange logo"
              src="@/assets/logo-white.png"
          /></a>
          <ul class="navbar-nav ml-auto navDesktop">
            <li class="nav-item">
              <b-nav-item  to="/" >Home</b-nav-item>
            </li>
          </ul>
          <div class="navMobile">
            <Slide right noOverlay class="burgerMenu" width="200">
              <a id="index" href="/">
                <span>Home</span>
              </a>
            </Slide>
          </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  name: "HeaderLogin",
  components: { Slide },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/scss/login.scss";
</style>

<style lang="css">
#index {
  text-decoration: none;
  margin-top: 25px;
  margin-right: 85px;
}
.bm-burger-bars {
      background-color: #fff !important;
    }
.nav-link{
  color:#fff !important;
}
</style>
