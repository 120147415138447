import Vue from "vue";
import VueRouter from "vue-router";
// import App from '../App.vue'
import EditAccount from "../views/EditAccount.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Index from "../views/Index.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Welcome from "../views/Welcome.vue";
import Home from "../views/Home.vue";
import MyAccount from "../views/MyAccount.vue";
import PachiShop from "../views/PachiShop.vue";
import Sweepstakes from "../views/Sweepstakes.vue";
import SweepstakesBuy from "../views/SweepstakesBuy.vue";
import SweepstakesResult from "../views/SweepstakesResult.vue";
import ProductConfirmation from "../views/ProductConfirmation.vue";
import ProductConfirmationGiftCard from "../views/ProductConfirmationGiftCard.vue";
import ProductConfirmationTrophy from "../views/ProductConfirmationPremiumTrophy.vue";
import ProductConfirmationMysteryBox from "../views/ProductConfirmationMysteryBox.vue";
import ProductProcessing from "../views/ProductProcessing.vue";
import ProductReview from "../views/ProductReview.vue";
import ProductVerify from "../views/ProductVerify.vue";
import Amazon20 from "../views/Amazon20.vue";
import Amazon50 from "../views/Amazon50.vue";
import Amazon100 from "../views/Amazon100.vue";
import AppleStore20 from "../views/AppleStore20.vue";
import AppleStore50 from "../views/AppleStore50.vue";
import AppleStore100 from "../views/AppleStore100.vue";
import GooglePlay20 from "../views/GooglePlay20.vue";
import GooglePlay50 from "../views/GooglePlay50.vue";
import GooglePlay100 from "../views/GooglePlay100.vue";
import Itunes20 from "../views/Itunes20.vue";
import Itunes50 from "../views/Itunes50.vue";
import Itunes100 from "../views/Itunes100.vue";
import MysteryBoxSmall from "../views/MysteryBoxSmall.vue";
import MysteryBoxMedium from "../views/MysteryBoxMedium.vue";
import MysteryBoxLarge from "../views/MysteryBoxLarge.vue";
import ProductConfirmation2 from "../views/ProductConfirmationRedeem.vue";
import ProductRedemptionCat from "../views/ProductRedemptionCat.vue";
import ProductRedemptionBuddha from "../views/ProductRedemptionBuddha.vue";
import ProductRedemptionCookie from "../views/ProductRedemptionCookie.vue";
import ProductRedemptionDragon from "../views/ProductRedemptionDragon.vue";
import ProductRedemptionIngot from "../views/ProductRedemptionIngot.vue";
import ProductRedemptionKoi from "../views/ProductRedemptionKoi.vue";
import TrophyBuddha from "../views/TrophyBuddha.vue";
import TrophyCat from "../views/TrophyCat.vue";
import TrophyCookie from "../views/TrophyCookie.vue";
import TrophyDragon from "../views/TrophyDragon.vue";
import TrophyIngot from "../views/TrophyIngot.vue";
import TrophyKoi from "../views/TrophyKoi.vue";
import CashoutAmount from "../views/CashoutAmount.vue";
import CashoutPayment from "../views/CashoutPayment.vue";
import CashoutPaymentDetails from "../views/CashoutPaymentDetails.vue";
import CashoutComplete from "../views/CashoutComplete.vue";
import ShopExchange from "../views/ShopExchange.vue";
import ProductRedemption from "../views/ProductRedemption.vue";
import { getAnalytics, logEvent } from "firebase/analytics";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Pachi Exchange",
        component: Index,
    },

    {
        path: "*",
        name: "Page Not Found",
        component: PageNotFound,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/create-account",
        name: "Register",
        component: Register,
    },
    {
        path: "/verifyemail",
        name: "Verify Email",
        component: VerifyEmail,
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/my-account",
        name: "My Account",
        component: MyAccount,
    },
    {
        path: "/my-account/edit",
        name: "Edit Account",
        component: EditAccount,
    },
    {
        path: "/shop",
        name: "Pachi Shop",
        component: PachiShop,
    },
    {
        path: "/sweepstakes",
        name: "Sweepstakes",
        component: Sweepstakes,
    },
    {
        path: "/sweepstakes/buy/:id",
        name: "Buy Sweepstakes",
        component: SweepstakesBuy,
    },
    {
        path: "/sweepstakes/result/:id",
        name: "Sweepstakes Result",
        component: SweepstakesResult,
    },
    {
        path: "/product/review",
        name: "Review",
        component: ProductReview,
    },
    {
        path: "/product/verify-email",
        name: "Verify Email",
        component: ProductVerify,
    },
    {
        path: "/product/processing",
        name: "Processing",
        component: ProductProcessing,
    },
    {
        path: "/product/confirmation",
        name: "Confirmation",
        component: ProductConfirmation,
    },
    {
        path: "/product/confirmationgiftcard",
        name: "ConfirmationGC",
        component: ProductConfirmationGiftCard,
    },
    {
        path: "/product/confirmationtrophy",
        name: "Confirmation Trophy",
        component: ProductConfirmationTrophy,
    },
    {
        path: "/product/confirmationmysterybox",
        name: "Confirmation Mystery Box",
        component: ProductConfirmationMysteryBox,
    },
    {
        path: "/prizes/amazon20",
        name: "Amazon $20",
        component: Amazon20,
    },
    {
        path: "/prizes/amazon50",
        name: "Amazon $50",
        component: Amazon50,
    },
    {
        path: "/prizes/amazon100",
        name: "Amazon $100",
        component: Amazon100,
    },
    {
        path: "/prizes/applestore20",
        name: "Apple Store $20",
        component: AppleStore20,
    },
    {
        path: "/prizes/applestore50",
        name: "Apple Store $50",
        component: AppleStore50,
    },
    {
        path: "/prizes/applestore100",
        name: "Apple Store $100",
        component: AppleStore100,
    },
    {
        path: "/prizes/googleplay20",
        name: "Google Play $20",
        component: GooglePlay20,
    },
    {
        path: "/prizes/googleplay50",
        name: "Google Play $50",
        component: GooglePlay50,
    },
    {
        path: "/prizes/googleplay100",
        name: "Google Play $100",
        component: GooglePlay100,
    },
    {
        path: "/prizes/itunes20",
        name: "iTunes $20",
        component: Itunes20,
    },
    {
        path: "/prizes/itunes50",
        name: "iTunes $50",
        component: Itunes50,
    },
    {
        path: "/prizes/itunes100",
        name: "iTunes $100",
        component: Itunes100,
    },
    {
        path: "/prizes/mysteryboxsmall",
        name: "Mystery Box Small",
        component: MysteryBoxSmall,
    },
    {
        path: "/prizes/mysteryboxmedium",
        name: "Mystery Box Medium",
        component: MysteryBoxMedium,
    },
    {
        path: "/prizes/mysteryboxlarge",
        name: "Mystery Box Large",
        component: MysteryBoxLarge,
    },
    {
        path: "/redeem/confirmation",
        name: "Confirmation",
        component: ProductConfirmation2,
    },
    {
        path: "/product/redeem/cat",
        name: "Redeem - Cat",
        component: ProductRedemptionCat,
    },
    {
        path: "/product/redeem/buddha",
        name: "Redeem - Buddha",
        component: ProductRedemptionBuddha,
    },
    {
        path: "/product/redeem/cookie",
        name: "Redeem - Cookie",
        component: ProductRedemptionCookie,
    },
    {
        path: "/product/redeem/dragon",
        name: "Redeem - Dragon",
        component: ProductRedemptionDragon,
    },
    {
        path: "/product/redeem/ingot",
        name: "Redeem - Ingot",
        component: ProductRedemptionIngot,
    },
    {
        path: "/product/redeem/koi",
        name: "Redeem - Koi",
        component: ProductRedemptionKoi,
    },
    {
        path: "/prizes/trophy/buddha",
        name: "Trophy - Buddha",
        component: TrophyBuddha,
    },
    {
        path: "/prizes/trophy/cat",
        name: "Trophy - Cat",
        component: TrophyCat,
    },
    {
        path: "/prizes/trophy/cookie",
        name: "Trophy - Cookie",
        component: TrophyCookie,
    },
    {
        path: "/prizes/trophy/dragon",
        name: "Trophy - Dragon",
        component: TrophyDragon,
    },
    {
        path: "/prizes/trophy/ingot",
        name: "Trophy - Ingot",
        component: TrophyIngot,
    },
    {
        path: "/prizes/trophy/koi",
        name: "Trophy - Koi",
        component: TrophyKoi,
    },
    {
        path: "/cashout/amount",
        name: "Cashout",
        component: CashoutAmount,
    },
    {
        path: "/cashout/payment-option",
        name: "Payment Option",
        component: CashoutPayment,
        props: true
    },
    {
        path: "/cashout/payment-details/:paymentType",
        name: "Payment Details",
        component: CashoutPaymentDetails,
        props: true
    },
    {
        path: "/cashout/complete",
        name: "Complete",
        component: CashoutComplete,
    },
    {
        path: "/shop/exchange/:id",
        name: "ShopExchange",
        component: ShopExchange,
    },
    {
        path: "/product/redeem/:redeemid/:exchangeid",
        name: "ProductRedeem",
        component: ProductRedemption,
    }
];

export const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/create-account', '/', '/welcome'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (authRequired && !loggedIn && !token) {
        return next('/login');
    }

    const analytics = getAnalytics();
    logEvent(analytics, to.path, { name: to.name});
    next();
})

export default router;
