<template>
  <div>
    <div>
      <HeaderIndex />
    </div>

    <section class="container-fluid">
        <!-- <section class="logo-group">
          <div class="logo-container">
              <img class="logo" src="@/assets/PachiExchangeLogo1.png" />
          </div>
              <p>Your Pachi+ Rewards Hub</p>
        </section> -->

        <section>
        <img
          src="@/assets/homebg.png"
          alt="Pachi Exchange Banner"
          class="banner desktop-banner"
        />
        <img
          src="@/assets/homebg-mobile.png"
          alt="Pachi Exchange Banner"
          class="banner mobile-banner"
        />
        </section>

        <Carousel />
 
        <section class="blue-banner">
            <div class="header">
                <h2 class="header-text">What is</h2>
                <div class="img-container">
                <img class="logo-text" src="@/assets/Asset 8@2x 1.png" />
                </div>
            </div>
            <div class="container">
                <video-embed :params="{autoplay: 1}" class="pachi-video" src="https://www.youtube.com/watch?v=h981DDVAVtk"></video-embed>
                <div class="content left-content">
                    <img src="@/assets/888.png" alt="Pachi+ Logo" />
                    <p>
                        A Pachinko game for both beginners and experienced players. Test your skills, face unique challenges, advance through various game levels and become number one on the Pachi+ leaderboard.
                    </p>
                    <a class="btn btn-info" role="button" href="https://pachiplus.com">Learn more</a>
                </div>
                <div class="content right-content">
                    <h2>The more you play, the more rewards!</h2>
                    <p>
                        Every game you play in Pachi+ generates Pachi Tickets. Whether you win the game or not, you are guaranteed to win and accummulate Pachi Tickets in your account. There’s literally no way you can lose in this game!
                    </p>
                    <a class="btn btn-info" role="button" href="https://pachiplus.com">Learn more</a>
                </div>
            </div>
        </section>

      <section class="pachi-rewards">
        <div class="header">
            <h2 class="header-text">Pachi+ Rewards Hub</h2>
            <p class="content">
                Pachi Exchange is the portal that handles your Pachi+ Tickets.
            </p>
        </div>
        <div class="exchange-container">
            <div class="left-content">
                <img class="pachiTicketImg" src="@/assets/pachiticket.png"/>
           </div>
            <div class="arrow-container">
                <img class="arrow-desktop" src="@/assets/arrow.png" alt="Arrow" />
                <img class="arrow-mobile" src="@/assets/arrowDown.png" alt="Arrow" />
            </div>
            <div class="right-content">
                <img class="pachiGift" src="@/assets/gift.png" />
           </div>
        </div>
        <div class="exchange-text-container">
            <p class="left-content">
                Your earned Pachi Tickets from your game sessions will not be put to waste! Your Pachi Tickets can be converted to prizes and sweepstakes entries on Pachi Exchange, your Pachi+ rewards hub.
            </p>
            <p class="right-content">
                Your Pachi+ account gives you access to Pachi Exchange. As you accumulate tickets while you play you are saving towards earning rewards through Pachi exchange.
            </p>
        </div>
      </section>
      <section class="contain-fluid">
        <img class="banner desktop-banner"
                src="@/assets/Pachi_Page3.png" 
                
                />
      </section>
      <section class="contain-fluid signup">
            <img
                src="@/assets/Background2.png"
                alt="Sign Up Banner"
                class="signup-cover img-desktop"
                />
            <img
                src="@/assets/index2ndBanner.png"
                alt="Sign Up Banner"
                class="signup-cover img-mobile" />
            <div class="text-container">
                <h2 class="header">Sign Up Now to Pachi Exchange</h2>
                <div class="signup-button">
                <a class="btn btn-info" :class="{'disabled': !ageVerified}" role="button" href="/create-account">SIGN ME UP</a>
                </div>
                <div style="text-align:center; color:#297fca;">
                    <input type="checkbox" name="ageVerify" class="verify-checkbox" v-model="ageVerified"/>
                    <label for="ageVerify" class="verify-label">
                        &nbsp;I am over 18
                    </label>
                </div>
                <div>
                    <p style="color:#297fca; font-weight: bold;">
                        Already have an account? <a href="/login" style="color:#297fca; text-decoration: underline;">Log in now</a>
                    </p>
                </div>

            </div>
      </section>

      <section class="platforms">
        <h2 class="header">Pachi+ is Available on Mobile and Desktop</h2>
        <div class="platform-imgs">
            <div class="platform">
            <a href="https://apps.apple.com/ph/app/pachi/id1558332832"> 
                <img src="@/assets/ios.png"/>
                <p>IOS</p>
                </a>
            </div>
            <div class="platform">
            <a href="https://play.google.com/store/apps/details?id=com.pachi.game&hl=en&gl=US">
                <img src="@/assets/android.png"/>
                <p>android</p>
                </a>
            </div>
            <div class="platform">
            <a href="https://play.pachiplus.com/">
                <img src="@/assets/windows.png"/>
                <p>windows</p>
                </a>
            </div>
        </div>
      </section>
    </section>
   <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderIndex from "../components/HeaderIndex";
import Footer from "../components/Footer";
import Carousel from '../components/Carousel.vue';
export default {
  name: "Index",
  components: {
    HeaderIndex,
    Footer,
    Carousel,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      ageVerified: false,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.btn {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    background-color: #297fca;
    border-radius: 11px;
    text-align: center;
    width: 204px;
    height: 64px;
    padding-top: 19px;

    &.disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
}

.container-fluid {
    padding: 0;

    .banner {
        width: 100%;
    }

    .desktop-banner {
        @media (max-width: 576px) {
            display: none;
        }
    }

    .mobile-banner {
        @media (min-width: 576px) {
            display: none;
        }

    }
}

.logo-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 16px;
    max-width: 75%;

    .logo-container {
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
    
    p {
        font-size: 24px;
        font-weight: bold;
    }
    @media (min-width: 576px) {
        position: absolute;
        left: 110px;
        top:  135px;
        align-items: flex-start;

        p {
            font-size: 16px;
            font-weight: normal;
        }

    }

    @media (min-width: 769px) {
        top: 220px;
    }
}

.carousel-desktop {
    @media (max-width: 576px) {
        display: none;
    }

    .carousel {
        margin-top: -100px;
        margin-bottom: 50px;
    }

    .item {
        margin: auto;
        max-width: 1460px;
        width: 85%;
    }

    .card {
        border: none;
        border-radius: 0;

        .card-title {
            margin-top: 3rem;
            font-weight: bold;
            font-size: 48px;
            width: 85%;
            margin-left: auto;
            margin-right: auto;
        }

        .card-text {
            margin-bottom: 3rem;
        }
    }
    
}

.carousel-mobile {
    @media (min-width: 576px) {
        display: none;
    }

    .carousel {
        margin: 0;
    }

    .item {
        margin: auto;
        max-width: 375px;
        max-height: 399px;
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

.blue-banner {
    padding-top: 55px;
    color: white;
    background-color: #063B58; 
    .header {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        margin-bottom: 32px;
        .header-text {
            line-height: 52px;
            margin: auto;
            margin-bottom: 0;
            font-size: 3rem;

            @media (min-width: 576px) {
                margin: 0;
                margin-right: 8px;

            }

            @media (min-width: 769px) {
                margin-bottom: 0;
            }
        }

        .img-container {
            margin: auto;
            @media (min-width: 576px) {
                margin: 0;
            }
    
        }

        @media (min-width: 576px) {
            flex-direction: row;
        }
    }

    .container {
        max-width: 1460px;
        padding-top:10px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        @media (min-width: 769px) {
            flex-direction: row;
        }
    }

    .pachi-video {
        flex: 2 1 100%;
        @media (min-width: 769px) {
            order: 1;
            align-self: center;
        }
    }

    .left-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-basis: 50%;
        margin-right: 24px;
        img, .btn {
            display: none;
            margin-top: 1rem;
            margin-bottom: 1rem;
            @media (min-width: 769px) {
                display: block;
            }
        }
        p {
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
        .btn {
            text-transform: uppercase;
            align-self: center;
        }
    }

    .right-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;
        flex: 1;
        flex-basis: 50%;
        @media (min-width: 769px) {
            margin-left: 24px;
        }

        h2 {
            font-size: 2.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: 700;
        }
        p {
            font-size: 1.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .btn {
            align-self: center;
            text-transform: uppercase;
            @media (min-width: 769px) {
                display:none;
            }
        }
    }
}

.pachi-rewards {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0 24px;
    color:#063B58;
    .header {
        .header-text {
            line-height: 52px;
            margin: auto;
            font-size: 2rem;
            font-weight: 700;

            @media (min-width: 576px) {
                font-size: 3rem;
                margin-bottom: 32px;
            }
        }

        .content {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 32px;

            @media (min-width: 576px) {
                font-size: 2rem;
            }
        }
    }
    .exchange-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (min-width: 576px) {
            flex-direction: row;
        }

        .left-content {
            img {
                max-width: 249px;
                width: 100%;
                object-fit: contain;
            }
            @media (min-width: 576px) {
                margin-right: 64px;
            }
        }

        .right-content {
            img {
                max-width: 144px;
                width: 100%;
                object-fit: contain;
            }
            @media (min-width: 576px) {
                margin-left: 64px;
            }
        }

        .arrow-container {
            .arrow-desktop {
                width: 100%;
                object-fit: contain;
                max-width: 220px;
                display: none;
                @media (min-width: 576px) {
                    display: block;
                }
            }
            .arrow-mobile {
                display: block;
                width: 100%;
                object-fit: contain;
                max-width: 80px;
                margin-top: 32px;
                margin-bottom: 32px;
                @media (min-width: 576px) {
                    display: none;
                }
            }
    
        }
    }

    .exchange-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 1150px;
        margin: auto;
        margin-top: 32px;
        margin-bottom: 32px;
        @media (min-width: 576px) {
            flex-direction: row;
            .left-content {
                margin-right: 32px;
            }
            .right-content {
                margin-left: 32px;
            }
        }
    }
}

.signup {
    position: relative;

    .signup-cover {
        width: 100%;
    }
    
    .img-mobile {
        display: block;
        @media (min-width: 769px) {
            display: none;
        }
    }

    .img-desktop {
        display: none;
        @media (min-width: 769px) {
            display: block;
        }
    }

    .text-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 16px;

        .header {
            font-weight: 700;
        }
        * {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .verify-label {
            margin-left: 8px;
        }
        @media (min-width: 576px) {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.signup-button a{
    background-color: #063B58;
    color: white;
}
.platforms {
    background-color: #063B58;
    color: white;
    padding: 0 16px;

    .header {
        font-weight: 700;
        font-size: 2rem;
        padding-top: 32px;

        @media (min-width: 576px) {
            font-weight: 3rem;
        }
    }

    .platform-imgs {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 1150px;
        width: 100%;
        align-items: flex-end;

        .platform {
           margin: 8px auto;

            a {
                color: white;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    color: white;
                }
            }

            p {
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin-top: 8px;
            }
        }

        @media (min-width: 576px) {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
}
</style>
